import React from "react";
import roomImg from "../../../../assets/Detail-Page/rooms_image.jpg";
const BookingInfo = ({
  bookingResponse,
  travellers,
  roomBreakDown,
  selectedRooms,
  totalSelectedRooms,
  payable_amount,
}) => {
  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  return (
    <div className="space-y-8">
      <div className="lg:flex space-y-2 md:space-y-0 justify-between items-center">
        <p className="text-[30px] font-700 text-brandGold">
          Your booking is in review
        </p>
        <div>
          <p className="text-[16px] font-500 text-primaryGray">
            Your submitted documents are currently in review
          </p>
          <p className="text-[16px] font-500 text-primaryGray lg:text-right">
            Thank you!
          </p>
        </div>
      </div>

      <div>
        <p className="text-[20px] font-600 text-primaryGray">
          Salam {bookingResponse.user_fullname}
        </p>
        <div className="text-primaryGray text-[16px] font-700">
          <p className="text-[16px] font-400 mt-2">
            Your advance payment of{" "}
            <span className="font-semibold">PKR 89,400</span> has been received
            and your documents are in review.
          </p>
          <p>
            We will get back to you once review is complete for the next steps.
          </p>
        </div>
      </div>
      <div className="text-primaryGray grid grid-cols-3 lg:w-2/3 xl:w-1/2">
        <div>
          <p className="text-[16px] font-500">Order date </p>
          <p className="text-[16px] font-400">
            {formatDate(bookingResponse.order_time)}
          </p>
        </div>
        <div>
          <p className="text-[16px] font-500">Order number</p>
          <p className="text-[16px] font-400">
            {bookingResponse.booking_number}
          </p>
        </div>
        <div>
          <p className="text-[16px] font-500">Payment</p>
          <p className="text-[16px] font-400">{bookingResponse.payment_type}</p>
        </div>
      </div>
      <div className="space-y-6">
        {roomBreakDown.map((room, index) => {
          const roomPrice = room.price * room.accommodates;
          return (
            <div key={index} className="space-y-6">
              <div className="border-b border-[#DFE4EA]" />
              <div className="md:flex space-y-4 md:space-y-0 items-center justify-between">
                <div className="flex items-center gap-8">
                  <img src={roomImg} alt="" className="w-30 h-20 rounded-md" />
                  <div>
                    <h3 className="text-sm font-semibold text-blueForDate">
                      {room.type}
                    </h3>
                    <p className="text-[16px] font-400 text-primaryGray">
                      {room.count} Room(s),PKR {roomPrice / room.accommodates} x{" "}
                      {room.accommodates} Person(s)
                    </p>
                  </div>
                </div>
                <p className="text-[16px] font-500 text-primaryGray">
                  QTY: {room.accommodates}
                </p>
                <p className="text-[16px] font-600 text-secondaryBlue">
                  PKR {roomPrice}
                </p>
              </div>
            </div>
          );
        })}
        <div className="border-b border-[#DFE4EA]" />
      </div>
      <div className="flex lg:justify-end">
        <div className="lg:w-[38%] w-full flex items-center justify-between">
          <p className="text-[16px] font-500 text-primaryGray">Subtotal:</p>
          <p className="text-[16px] font-600 text-secondaryBlue">
            PKR: {bookingResponse.total_price}
          </p>
        </div>
      </div>
      <div className="flex lg:justify-end">
        <div className="lg:w-[38%] w-full flex items-center justify-between">
          <p className="text-[16px] font-500 text-primaryGray">Total paid:</p>
          <p className="text-[16px] font-600 text-secondaryBlue">
            PKR: {payable_amount}
          </p>
        </div>
      </div>
      <div className="flex lg:justify-end">
        <div className="lg:w-[38%] w-full flex items-center justify-between">
          <p className="text-[16px] lg:w-1/2 font-500 text-primaryGray">
            Remaining payment after confirmation:
          </p>
          <p className="text-[16px] font-600 text-secondaryBlue">
            PKR: {bookingResponse.total_price - payable_amount}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingInfo;
