import React, { useContext, useState } from "react";
import { AiOutlineMenu, AiOutlineClose, AiOutlineUser } from "react-icons/ai";
import { motion } from "framer-motion";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logoIcon from "../../assets/logoIcon.png";
import AuthContext from "../../context/AuthContext";
import { Login, Modal, SignUp } from "../../index";
import { useModal } from "../../context/ModalContext";
import { CgMenuRound } from "react-icons/cg";

const navLinks = [
  { href: "/listing-page", label: "Hajj", type: "Hajj" },
  { href: "/listing-page", label: "Umrah", type: "Umrah" },
  { href: "/visa-services", label: "Visa" },
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [lnIsOpen, setLnIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const { pathname } = useLocation();
  const { login, signOut } = useContext(AuthContext);
  const { setIsModalOpen } = useModal();
  const navigate = useNavigate();
  const isHomeRoute = pathname === "/"; // Check if the current route is home
  const handleToggle = (setter) => () => setter((prev) => !prev);
  const handleNavigate = (href, type) => () => {
    navigate(type ? `${href}?type=${type}` : href);
    if (isMenuOpen) setIsMenuOpen(false);
  };

  // Open SignUp Modal
  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalType(null); // Close modal
    setIsModalOpen(false);
  };
  const renderLinks = (isMobile = false) =>
    navLinks.map(({ href, label, type }) => (
      <a
        key={href}
        onClick={handleNavigate(href, type)}
        className={`text-[16px] font-medium leading-[24px] ${
          pathname === href
            ? "text-primaryGray"
            : isHomeRoute
            ? "text-white hover:text-brandGold"
            : "text-primaryGray   hover:text-brandGold"
        } ${isMobile ? "py-4" : ""}`}
      >
        {label}
      </a>
    ));

  return (
    <header className="w-all-sections-width mx-auto flex justify-between items-center py-8">
      {/* Logo */}
      <div className="flex items-center gap-4">
        <img src={logoIcon} alt="Logo Icon" className=" w-[28.95px] h-[40px]" />
        <a
          href="/"
          className={`text-[28px] font-medium leading-[40px] tracking-wider ${
            isHomeRoute ? "text-white" : "text-black"
          }`}
        >
          HajjUmrah.co
        </a>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex gap-10">{renderLinks()}</nav>

      {/* Mobile Hamburger Icon */}
      <button
        onClick={handleToggle(setIsMenuOpen)}
        aria-label="Toggle Menu"
        className={`lg:hidden ${isHomeRoute ? "text-white" : "text-black"}`}
      >
        {isMenuOpen ? (
          <AiOutlineClose size={24} />
        ) : (
          <AiOutlineMenu size={24} />
        )}
      </button>
      <div className="hidden lg:flex gap-6 items-center">
        <NavLink to={"https://operator.hajjumrah.co/"}>
          <button
            className={`text-[16px] cursor-pointer font-medium leading-[24px] ${
              isHomeRoute
                ? "text-white hover:text-brandGold"
                : "text-primaryGray   hover:text-brandGold"
            }`}
          >
            Become a Partner
          </button>
        </NavLink>
        <button
          onClick={() => navigate("/Home-Page-Arabic")}
          className={`text-[16px] cursor-pointer font-medium leading-[24px] ${
            isHomeRoute
              ? "text-white hover:text-brandGold"
              : "text-primaryGray   hover:text-brandGold"
          }`}
        >
          العربية
        </button>

        {login ? (
          <div className="relative">
            <button
              className={`flex items-center gap-2 text-[16px] font-medium ${
                isHomeRoute ? "text-white" : "text-black"
              }`}
              onClick={handleToggle(setIsOpen)}
            >
              <AiOutlineUser
                size={40}
                className="p-2 bg-brandGold text-white rounded-3xl"
              />
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md py-2 w-48 z-50">
                <NavLink to="/all-bookings">
                  <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-brandGold">
                    All bookings
                  </button>
                </NavLink>
                <button
                  onClick={() => {
                    signOut();
                    setIsOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <CgMenuRound
              onClick={handleToggle(setLnIsOpen)}
              className="text-3xl text-brandGold cursor-pointer"
            />
            {lnIsOpen && (
              <div className="absolute cursor-pointer hover:text-brandGold mt-36 bg-white shadow-lg rounded-md py-2 w-48 right-16 z-50">
                <button
                  onClick={() => openModal("login")}
                  className="block w-full text-[16px] py-2 font-medium leading-[24px] text-primaryGray hover:text-brandGold"
                >
                  Login
                </button>
                <button
                  onClick={() => openModal("signup")}
                  className="block w-full px-6 py-2 text-brandGold hover:text-brandGold"
                >
                  Sign Up
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ duration: 0.3 }}
          className={`absolute pb-6 top-20 left-0 w-full ${
            isHomeRoute
              ? "bg-black bg-opacity-80 text-white"
              : "bg-white text-lightGray"
          } flex flex-col items-center lg:hidden z-50`}
        >
          {renderLinks(true)}
          {login ? (
            <div>
              <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-brandGold">
                All Bookings
              </button>
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={signOut}
              >
                Sign Out
              </button>
            </div>
          ) : (
            <>
              {/* Login Button */}
              <button
                className={`text-[16px] font-medium leading-[24px] mt-4 ${
                  isHomeRoute ? "hover:text-brandGold" : "hover:text-brandGold"
                }`}
                onClick={() => openModal("login")}
              >
                Login
              </button>
              <button
                className="px-6 py-2 border border-brandGold text-brandGold rounded-full mt-4 hover:bg-brandGold hover:text-white"
                onClick={() => openModal("signup")}
              >
                Sign Up
              </button>
            </>
          )}
        </motion.div>
      )}
      <Modal isOpen={!!modalType} onClose={closeModal}>
        {modalType === "login" ? (
          <Login
            closeModal={closeModal}
            openSignUpModal={() => openModal("signup")}
          />
        ) : (
          <SignUp
            closeModal={closeModal}
            openLoginModal={() => openModal("login")}
          />
        )}
      </Modal>
    </header>
  );
};

export default Header;
