import React, { useContext } from "react";
import { Calendar, Clock, CheckCircle, AlertCircle, Users } from "lucide-react";
import visaImg from "../../../../assets/Detail-Page/visa.png";
import masterImg from "../../../../assets/Detail-Page/master.png";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
const BookingSummary = ({
  data,
  travellers,
  handleClick,
  roomBreakDown,
  total_amount,
  payable_amount,
  isRoomSelectionValid,
  handleSubmit,
  handleTravellerChange,
}) => {
  const { start_date } = data;
  const { login } = useContext(AuthContext);
  const isUserLoggedIn = login?.session_token;
  const isButtonEnabled = isRoomSelectionValid && isUserLoggedIn;
  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = () => {
    navigate(-1);
  };
  return (
    <div className="w-full bg-white rounded-lg border border-[#DFE4EA] p-6 space-y-6">
      {/* Error Alert */}
      {(!isRoomSelectionValid || !isUserLoggedIn) && (
        <div className="flex items-center gap-3 bg-[#FEF3F3] p-4 rounded-lg">
          <AlertCircle className="w-5 h-5 text-[#E10E0E]" />
          <p className="text-[#BC1C21] text-sm font-medium">
            {isRoomSelectionValid
              ? "Please log in to continue with the booking."
              : "Select enough beds for people in your group."}
          </p>
        </div>
      )}

      {/* Price Header */}
      <div className="space-y-4">
        <h2 className="text-lg font-semibold text-brandGold">
          <NumericFormat
            value={total_amount}
            displayType={"text"}
            thousandSeparator
            prefix={"PKR "}
            decimalScale={2}
            fixedDecimalScale={true}
          />{" "}
          / {travellers} travelers
        </h2>
        <div className="border-b border-[#DFE4EA]" />
      </div>

      {/* Form Fields */}
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium text-blueForDate">
            Departure date
          </label>
          <div className="flex items-center gap-2 p-3 border border-[#DFE4EA] rounded-md bg-white/70">
            <Calendar className="w-4 h-4 text-[#6B7280]" />
            <input
              type="text"
              value={`${new Date(start_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}`}
              className="bg-transparent text-sm outline-none text-[#9CA3AF]"
              readOnly
            />
          </div>
        </div>

        <div className="space-y-2 ">
          <label className="text-sm font-medium text-blueForDate">
            Total travellers
          </label>
          <div className="flex gap-2 w-full">
            <div
              className={`flex items-center text-secondaryBlue ${
                location.pathname === "/package-detail-page/"
                  ? "block"
                  : "hidden"
              }`}
            >
              <button
                className="border border-[#DFE4EA] lg:px-1.5 xl:px-3 px-3 py-1 hover:bg-gray-200 text-base font-[500] hover:scale-110 transition-transform duration-200"
                onClick={() => handleTravellerChange("decrease")}
              >
                -
              </button>
              <span className="border-y border-[#DFE4EA] lg:px-3 xl:px-5 px-5 py-1 text-base font-[500] hover:bg-gray-200 hover:scale-110 transition-transform duration-200">
                {travellers}
              </span>
              <button
                className="border border-[#DFE4EA] lg:px-1.5 xl:px-3 px-3 py-1 text-base font-[500] hover:bg-gray-200 hover:scale-110 transition-transform duration-200"
                onClick={() => handleTravellerChange("increase")}
              >
                +
              </button>
            </div>
            <div className="w-full flex items-center justify-between p-3 py-2 border border-[#DFE4EA] rounded-md bg-white/70">
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4 text-[#6B7280]" />
                <span className="text-[#9CA3AF] text-sm">
                  {travellers} Travelers
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div className="border-b border-[#DFE4EA]" />
        {roomBreakDown.map((room, index) => {
          const roomPrice = room.price * room.accommodates;
          return (
            <div key={index} className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-semibold text-blueForDate">
                  {room.type} - {room.count} Room(s)
                </h3>
              </div>
              <p className="text-xs text-[#637381]">
                PKR {roomPrice} for {room.accommodates} Person(s)
              </p>
            </div>
          );
        })}
      </div>

      {/* Total Section */}
      <div className="space-y-4">
        <div className="border-b border-[#DFE4EA]" />
        <div className="space-y-1">
          <div className="flex justify-between text-sm font-bold">
            <span>TOTAL PAYABLE</span>
            <NumericFormat
              value={total_amount}
              displayType={"text"}
              thousandSeparator
              prefix={"PKR "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
          <div className="flex justify-between text-sm font-bold text-[#22AD5C]">
            <span>PAY NOW</span>
            <NumericFormat
              value={payable_amount}
              displayType={"text"}
              thousandSeparator
              prefix={"PKR "}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </div>
      </div>

      <button
        disabled={!isButtonEnabled}
        className={`w-full py-3 px-7 ${
          isButtonEnabled
            ? "bg-brandGold text-white"
            : "bg-[#E5E7EB] text-[#6B7280]"
        }  rounded-md font-medium ${
          location.pathname === "/package-detail-page/" ? "block" : "hidden"
        }`}
        onClick={() => {
          if (isRoomSelectionValid) handleClick();
        }}
      >
        Book Now
      </button>
      <button
        onClick={handleSubmit}
        className={`w-full py-3 px-7 ${
          isButtonEnabled
            ? "bg-brandGold text-white"
            : "bg-[#E5E7EB] text-[#6B7280]"
        }  rounded-md font-medium ${
          location.pathname === "/package-detail-page/" ? "hidden" : "block"
        }`}
      >
        Book Now
      </button>
      <button
        onClick={handleChange}
        className={`w-full py-3 px-7 text-secondaryBlue shadow-sm bg-white rounded-md font-medium ${
          location.pathname === "/package-detail-page/" ? "hidden" : "block"
        }`}
      >
        Back
      </button>
      {/* Footer Info */}
      <div className="space-y-2">
        <div className="flex items-center gap-3 text-[#637381]">
          <Clock className="w-5 h-5 text-brandGold" />
          <span className="text-sm">Booking only takes 2 minutes</span>
        </div>
        <div className="flex items-center gap-3 text-[#637381]">
          <CheckCircle className="w-5 h-5 text-brandGold" />
          <span className="text-sm">Instant confirmation</span>
        </div>
      </div>

      {/* Payment Methods */}
      <div className="flex items-center gap-2">
        <img src={visaImg} alt="Visa" className="h-4" />
        <img src={masterImg} alt="Mastercard" className="h-4" />
      </div>
    </div>
  );
};

export default BookingSummary;
