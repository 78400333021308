import React from "react";
import jazzLogo from "../../../assets/Payment/jazz.svg";
import lockLogo from "../../../assets/Payment/lock.png";
import ecllipseLogo from "../../../assets/Payment/ecllipse.png";
const JazzInfo = ({
  transferID,
  validateTransferID,
  handleTransferInputChange,
  errors
}) => {
  return (
    <div className="text-secondaryBlue">
      <p className="text-[24px] font-600 my-2">Jazz Transfer </p>
      <div className="space-y-5 p-6 border-[1px] border-[#dfe4ea] bg-white rounded-md">
        <div>
          <img src={jazzLogo} alt="Bank" className="h-12" />
        </div>
        <div className="space-y-1">
          <label>Jazz Account Number:</label>
          <input
            type="text"
            placeholder="Enter Account Number"
            value={transferID}
            onChange={handleTransferInputChange}
            onBlur={() => validateTransferID(transferID)}
            className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#dfe4ea]"
          />
        </div>
        {errors.file && <p className="text-red-500 text-xs">{errors.file}</p>}
        <div className="flex gap-2 items-center">
          <div className="relative ">
            <img src={ecllipseLogo} alt="ecllipse" className="object-cover" />
            <img
              src={lockLogo}
              alt="lock"
              className="absolute top-1/2 left-3.5 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>

          <p className="text-[14px] font-400 text-[#A8A8AA]">
            We protect your payment information using encryption to provide
            bank-level security.
          </p>
        </div>
      </div>
    </div>
  );
};

export default JazzInfo;
