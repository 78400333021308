import React from "react";
import { Footer, Header } from "../../../";
const BrowsingTipsPage = () => {
  const preparationTips = [
    {
      title: "Strengthen Your Spiritual Connection",
      description:
        "Begin by purifying your intention to perform Hajj or Umrah solely for the sake of Allah. Familiarize yourself with the rituals, increase prayers, recite the Quran, and perform acts of charity to spiritually prepare.",
    },
    {
      title: "Organize Travel and Accommodation",
      description:
        "Book through a trusted travel agency, ensure your documents are valid, and select accommodations close to Masjid al-Haram or Masjid al-Nabawi.",
    },
    {
      title: "Physical Preparation",
      description:
        "Visit a doctor for a health check-up, practice walking long distances, and pack essentials like comfortable footwear, Ihram garments, and unscented toiletries.",
    },
    {
      title: "Financial Planning",
      description:
        "Calculate expenses, clear debts, and set aside funds for charity as part of your journey’s blessings.",
    },
    {
      title: "Learn Practical Etiquette",
      description:
        "Respect fellow pilgrims, follow local laws, and practice patience and gratitude during the journey.",
    },
    {
      title: "Mental and Emotional Readiness",
      description:
        "Seek guidance from those who have performed Hajj or Umrah, memorize important duas, and focus on the spiritual significance of the journey.",
    },
    {
      title: "Prepare for Time in Makkah and Madinah",
      description:
        "Plan visits to significant sites, rest adequately, and stay hydrated during the trip.",
    },
  ];
  return (
    <div className="text-secondaryBlue">
      <Header />
      <div className="w-all-sections-width mx-auto py-10">
        <h1 className="text-4xl font-bold text-center">
          Preparation Tips for Hajj and Umrah
        </h1>

        <div className="py-8 space-y-4">
          <section className="mb-8">
            <h2 className="text-[20px] mb-4 font-bold">
              Preparation Tips for Hajj and Umrah
            </h2>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px]">
              {preparationTips.map((tip, index) => (
                <li key={index}>
                  <strong>{tip.title}:</strong> {tip.description}
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BrowsingTipsPage;
