import React from "react";
import { Footer, Header } from "../../../../";

const HajjGuidance = () => {
  const stepsOfHajj = [
    "Preparation and Intention",
    "Enter state of Ihram",
    "Tawaf x7",
    "Safa and Marwa",
    "Clip/Shave Hair (Umrah ends)",
    "Resting and Praying",
    "Enter state of Ihram",
    "Arrive at Mina",
    "Day of Arafah",
    "Muzdalifah (under the night sky)",
    "Rami (stoning of the devil)",
    "Hady",
    "Shaving of the Head",
    "Tawaf al-Ifadha",
    "Saai’",
    "Rami (stoning of the devil)",
    "Spend night at Mina",
    "Rami (stoning of the devil)",
    "Farewell Tawaf al-Wida",
  ];

  const renderEligibilityConditions = () => (
    <ol className="list-decimal list-inside text-[16px] mb-4 space-y-2 text-secondaryBlue">
      <li>
        Only Muslim adults (male or female) are required to perform{" "}
        <em>Hajj</em>.
      </li>
      <li>
        The very weak, sick, elderly, or otherwise physically incapable Muslims
        are exempt.
      </li>
      <li>
        Muslims must be financially able to perform <em>Hajj</em>. This
        includes:
        <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
          <li>The creditor permits it</li>
          <li>The debtor has time to pay off the debt</li>
          <li>
            <em>Hajj</em> does not affect their ability to pay off the debt
          </li>
        </ul>
      </li>
    </ol>
  );

  return (
    <div className="text-secondaryBlue">
      <Header />
      <div className="w-all-sections-width mx-auto py-10">
        <h1 className="text-4xl font-bold text-center mb-8">
          Step by step guide to performing Hajj
        </h1>

        <section className="mb-8">
          <h2 className="text-[20px] font-bold mb-4">What Is Hajj?</h2>
          <p className="mb-4">
            Each year, millions of Muslims from all across the world perform{" "}
            <em className="border-b-2 border-brandGold">Hajj</em>, the sacred
            pilgrimage and the fifth pillar of Islam.
          </p>
          <p className="mb-4">
            <em className="border-b-2 border-brandGold">Hajj</em> takes place in
            Makkah, in modern day Saudi Arabia, during the holy month of Dhul
            Hijjah, the 12th month in the Islamic calendar.
          </p>
          <p className="mb-4">
            <em className="border-b-2 border-brandGold">Hajj</em> is a spiritual
            duty and a <strong>pillar of Islam</strong>, meaning that{" "}
            <strong>
              Hajj must be performed by every Muslim at least once in their
              lifetime
            </strong>
            , so long as they are financially, physically, and emotionally able
            to do so. Going more than once during your lifetime is permitted
            whilst sincerely seeking Allah’s (SWT) pleasure.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-[20px] font-bold mb-4">
            Who Is Eligible To Perform Hajj?
          </h2>
          <p className="text-[16px] mb-4">
            Hajj forms one of the five pillars of Islam. As such, all Muslims
            must perform Hajj at least once in their lifetime.
          </p>
          <p className="text-[16px] mb-4">
            However, there are still certain conditions that need to be met in
            order to perform <em>Hajj</em>:
          </p>
          {renderEligibilityConditions()}
        </section>

        <section className="mb-8">
          <h2 className="text-[20px] font-bold font-kd mb-4">
            The 19 Steps Of Hajj At A Glance
          </h2>
          <ol className="list-decimal list-inside text-[16px] mb-4 space-y-2">
            {stepsOfHajj.map((step, index) => (
              <li
                key={index}
                className="before:content-[counter(list-item)] before:text-brandGold list-none"
              >
                &nbsp;&nbsp; {step}
              </li>
            ))}
          </ol>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default HajjGuidance;
