import React from "react";
import voucherImage from "../../../assets/Payment/voucherImage.png";
import uploadLogo from "../../../assets/Payment/uploadlogo.png";
const VoucherInfo = ({ previewFile, handleFileChange, errors }) => {
  return (
    <div className="text-secondaryBlue">
      <div className="flex justify-between items-center py-2">
        <p className="text-[24px] font-600 my-2">Voucher </p>
        <a
          href="/assets/voucherImage.png"
          download="Voucher.png"
          target="_blank"
        >
          <button className="bg-brandGold text-white p-2 rounded-md">
            Download Voucher
          </button>
        </a>
      </div>
      <div className="space-y-5 p-6 border-[1px] border-[#dfe4ea] bg-white rounded-md">
        <img src={voucherImage} alt="" />
        <div className="space-y-1">
          <label className="text-[18px] font-600 ">
            For verification purposes, kindly upload paid voucher:
          </label>
          <label htmlFor="fileInput">
            <div
              className={`h-24 w-full border border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center cursor-pointer relative`}
            >
              <img
                src={uploadLogo}
                alt="Custom Icon"
                className="h-6 w-6 text-gray-500 mb-2"
              />
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <p className="text-gray-600 font-light">
                Drop files here or click to upload
              </p>
            </div>
          </label>
          {previewFile && (
            <img src={previewFile} alt="Preview" className="w-[20%]" />
          )}
          {errors.file && <p className="text-red-500 text-xs">{errors.file}</p>}
        </div>
      </div>
    </div>
  );
};

export default VoucherInfo;
