import React from "react";
import { FaCheck } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../Utilities/UtilityFunctions";
import airPlane from "../../assets/homepage/popularPackage/airFlight.png";
import hotels from "../../assets/homepage/popularPackage/hotel.png";
import transport from "../../assets/homepage/popularPackage/transport.png";
import saudiVisa from "../../assets/homepage/popularPackage/visa.png";
import { useNavigate } from "react-router-dom";

const PackageCardHome = ({ packageData }) => {
  const {
    huz_token,
    package_name,
    company_details: { company_name } = {},
    start_date,
    end_date,
    hotel_detail = [],
    is_visa_included,
    is_flight_included,
    is_transport_included,
    is_tour_guide_included,
    depart_city,
    cost_for_sharing,
    airline_detail = [],
  } = packageData;
  const navigate = useNavigate();
  // Determine the number of days and nights
  const totalDays = Math.floor(
    (new Date(end_date) - new Date(start_date)) / (1000 * 3600 * 24)
  );
  const totalNights = totalDays - 1;
  const daysAndNightsLabel = `${totalDays} Days ${totalNights} Nights`;
  const airlineDetail = airline_detail.length > 0 ? airline_detail[0] : null;
  // Determine departure city (fallback to 'Islamabad')
  const departCity = depart_city || "Islamabad";

  // Dynamic inclusions array with new conditions
  const inclusions = [
    { label: daysAndNightsLabel, included: true },
    { label: `Departs from ${departCity}`, included: true },
    ...(is_tour_guide_included
      ? [{ label: "Makkah & Madinah Ziyarah", included: true }]
      : []),
  ];

  // Determine hotel label based on availability
  let hotelLabel = "No Hotel";

  if (hotel_detail.length === 1) {
    hotelLabel = "1 Hotel"; // Show '1 Hotel' if there's only one hotel
  } else if (hotel_detail.length === 2) {
    hotelLabel = "2 Hotels"; // Show '2 Hotels' if there are exactly two hotels
  }

  //by default 1
  const travellers = 1;
  const handleClick = () => {
    navigate(`/package-detail-page/?PackageId=${huz_token}`, {
      state: {
        huz_token,
        travellers,
      },
    });
  };
  return (
    <div className="rounded-lg lg:w-[270px] xl:w-auto h-[470px] overflow-hidden transition-transform duration-300 hover:scale-105 flex flex-col">
      {/* Top Image Section with Swiper */}
      <div className="relative w-full h-[270px] overflow-hidden rounded-lg">
        {hotel_detail.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            autoplay={{ delay: 5000, disableOnInteraction: false }} // Autoplay added here
            pagination={false}
            navigation={false}
            modules={[Autoplay]} // Ensure the Autoplay module is included
          >
            {hotel_detail.map((hotel) => {
              const images = getHotelImages(hotel.hotel_name, [
                ...makkahHotels,
                ...madinahHotels,
              ]);
              return images.length > 0 ? (
                images.map((image, idx) => (
                  <SwiperSlide key={`${hotel.hotel_name}-${idx}`}>
                    <div className="relative w-full h-full">
                      <img
                        src={image}
                        alt={hotel.hotel_name}
                        className="object-cover w-full h-full"
                        style={{
                          width: "100%",
                          height: "270px",
                        }}
                      />
                      {/* Gradient Overlay */}
                      <div
                        className="absolute inset-0"
                        style={{
                          background:
                            "linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)",
                        }}
                      ></div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide key={hotel.hotel_name}>
                  <div className="relative w-full h-full ">
                    <img
                      src="https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D"
                      alt="Default Hotel"
                      className="object-cover w-full h-full"
                      style={{
                        width: "100%",
                        height: "270px",
                      }}
                    />
                    {/* Gradient Overlay */}
                    <div
                      className="absolute inset-0"
                      style={{
                        background:
                          "linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)",
                      }}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="relative w-full h-full">
            <img
              src="default-image-url.jpg"
              alt="Default Package"
              className="object-cover w-full h-full"
              style={{
                width: "100%",
                height: "270px",
              }}
            />
            {/* Gradient Overlay */}
            <div
              className="absolute inset-0"
              style={{
                background:
                  "linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)",
              }}
            ></div>
          </div>
        )}
        <div onClick={handleClick}>
          <div className="absolute top-4 right-4 text-start bg-white text-brandGold px-2.5 py-2 rounded-full z-50 cursor-pointer">
            <p className="text-sm text-center">
              {totalDays} <br /> days
            </p>
          </div>
          <div className="absolute bottom-4 left-4 text-start text-white z-50 cursor-pointer">
            <h3 className="font-[500] text-lg lg:text-2xl">{package_name}</h3>
            <p className="text-brandGold font-[600] text-sm">
              By {company_name}
            </p>
            <p className="text-sm font-semibold">
              {new Date(start_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}{" "}
              till{" "}
              {new Date(end_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </p>
          </div>
        </div>
      </div>

      {/* Lower Section with Border */}
      <div
        className="bg-white border-[1px] border-custom-gray rounded-b-lg px-6 pb-4 flex flex-col flex-1 justify-between cursor-pointer"
        onClick={handleClick}
      >
        {/* Features Row Section */}
        <div className="flex px-2 justify-between mt-4 mb-6 text-gray-500 text-xs">
          <div className="flex items-center flex-col">
            <img
              src={airPlane}
              alt="airPlane"
              className={`${
                airlineDetail?.is_return_flight_included
                  ? "text-brandGold"
                  : "text-mediumBlue"
              } mb-1`}
            />
            <span className="text-xs text-gray-500">
              {airlineDetail?.is_return_flight_included
                ? "Round Trip"
                : is_flight_included
                ? "One Way"
                : "No Flight"}
            </span>
          </div>
          <div className="flex items-center flex-col">
            <img src={hotels} alt="hotel" className="text-brandGold mb-1" />
            <span className="text-xs text-gray-500">{hotelLabel}</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={transport}
              alt="transport"
              className={`${
                is_transport_included ? "text-brandGold" : "text-mediumBlue"
              } mb-1`}
            />
            <span className="text-xs text-gray-500">Transport</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={saudiVisa}
              alt="visa"
              className={`${
                is_visa_included ? "text-brandGold" : "text-mediumBlue"
              } mb-1`}
            />
            <span className="text-xs text-gray-500">Visa</span>
          </div>
        </div>

        {/* Inclusions Section */}
        <div className="space-y-2 mb-4">
          {inclusions.map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-4 font-light text-secondaryGray"
            >
              <FaCheck className="text-brandGold" />
              <span className="text-sm">{item.label}</span>
            </div>
          ))}
        </div>

        {/* Price Section - Always at the Bottom */}
        <div>
          <p className="text-brandGold font-[500] text-xs">Starting from</p>
          <div className="flex justify-between items-center mt-auto">
            <p className="text-darkBlue text-end lg:text-lg xl:text-xl font font-medium line-height-0-lg">
              PKR {cost_for_sharing}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCardHome;
