import React, { useContext, useEffect, useState } from "react";
import {
  createBookingRequest,
  getPackageDetailByPackageId,
} from "../../api/apiService";
import {
  AirlineDetails,
  BookingSummary,
  Footer,
  Header,
  Itinerary,
  MessageDisplay,
  PackageHeader,
  PackageItinerary,
  RoomOptions,
  TransportDetails,
  Verification,
} from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import { useLocation, useNavigate } from "react-router-dom";
import roomImg from "../../assets/Detail-Page/rooms_image.jpg";
import AuthContext from "../../context/AuthContext";
const PackageDetailPage = () => {
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const huz_token = searchParams.get("PackageId");
  const { login } = useContext(AuthContext);
  const { initialTravellers } = location.state || {};
  const [travellers, setTravellers] = useState(initialTravellers || 1);

  const handleTravellerChange = (type) => {
    setTravellers((prev) => {
      if (type === "increase") return prev + 1;
      if (type === "decrease" && prev > 1) return prev - 1;
      return prev;
    });
  };
  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const result = await getPackageDetailByPackageId({
          huz_token,
        });
        if (result.success && result.packageDetail.length > 0) {
          setPackageData(result.packageDetail[0]);
        }
      } catch (error) {
        console.error("Error fetching package details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPackageDetails();
  }, []);
  const [selectedRooms, setSelectedRooms] = useState({
    double: 0,
    triple: 0,
    quad: 0,
    sharing: 0,
  });

  // Use data directly for prices
  const prices = {
    cost_for_double: packageData && packageData.cost_for_double,
    cost_for_triple: packageData && packageData.cost_for_triple,
    cost_for_quad: packageData && packageData.cost_for_quad,
    cost_for_sharing: packageData && packageData.cost_for_sharing,
  };
  const rooms = [
    {
      type: "Sharing Bed Room",
      price: prices.cost_for_sharing,
      totalPrice: prices.cost_for_sharing,
      image: roomImg, // Update with actual image URL if available in data
      description: "Room with 5 single beds.",
      sleeps: 5,
      bathroom: "Sharing Bathroom",
      tags: ["Best private room price", "Only 3 beds left!"],
      key: "sharing",
    },
    {
      type: "Quad Bed Room",
      price: prices.cost_for_quad,
      totalPrice: prices.cost_for_quad,
      image: roomImg,
      description: "Room with four single beds.",
      sleeps: 4,
      bathroom: "Private Bathroom",
      tags: ["Only 1 bed left!"],
      key: "quad",
    },
    {
      type: "Triple Bed Room",
      price: prices.cost_for_triple,
      totalPrice: prices.cost_for_triple,
      image: roomImg,
      description: "Room with three single beds.",
      sleeps: 3,
      bathroom: "Private Bathroom",
      tags: ["Only 2 bed left!"],
      key: "triple",
    },
    {
      type: "Double bed Room",
      price: prices.cost_for_double,
      totalPrice: prices.cost_for_double,
      image: roomImg,
      description: "Room with double or two single beds.",
      sleeps: 2,
      bathroom: "Private Bathroom",
      tags: ["Best sharing price", "Only 1 room left!"],
      key: "double",
    },
  ];
  let remainingTravellers = travellers;
  const roomBreakDown = Object.keys(selectedRooms)
    .map((roomKey) => {
      const room = rooms.find((r) => r.key === roomKey);
      if (room && selectedRooms[roomKey] > 0) {
        const roomCapacity = room.sleeps * selectedRooms[roomKey];
        const travellersInThisRoom = Math.min(
          remainingTravellers,
          roomCapacity
        );
        remainingTravellers -= travellersInThisRoom;
        return {
          type: room.type,
          count: selectedRooms[roomKey],
          price: room.price,
          accommodates: selectedRooms[roomKey],
        };
      }
      return null;
    })
    .filter((item) => item !== null);
  const totalRoomCost = roomBreakDown.reduce(
    (acc, room) => acc + room.price * room.count,
    0
  );
  const package_cost = packageData ? packageData.package_base_cost : "";
  const total_amount = totalRoomCost + package_cost * travellers;
  const payable_amount = (total_amount * 10) / 100;
  const navigate = useNavigate();
  const checkRoomSelection = () => {
    // Calculate the total selected capacity
    let totalCapacity = Object.values(selectedRooms).reduce(
      (sum, count) => sum + count,
      0
    );

    // Return true only if total capacity exactly matches the number of travelers
    return totalCapacity >= travellers;
  };

  const totalSelectedRooms = Object.values(selectedRooms).reduce(
    (sum, count) => sum + count,
    0
  );
  const isRoomSelectionValid = checkRoomSelection();
  const handleClick = async () => {
    let session_token = "";
    if (login) {
      const newUserProfile = JSON.parse(
        localStorage.getItem("New-User-Profile") || "{}"
      );
      session_token =
        login?.session_token || newUserProfile.session_token || "";
    }
    const roomCounts = Object.keys(selectedRooms).reduce((acc, roomKey) => {
      acc[roomKey] = selectedRooms[roomKey];
      return acc;
    }, {});
    const payload = {
      session_token,
      partner_session_token: packageData.partner_session_token,
      huz_token: packageData.huz_token,
      adults: travellers,
      child: 0,
      infants: 0,
      sharing: roomCounts.sharing || 0,
      quad: roomCounts.quad || 0,
      triple: roomCounts.triple || 0,
      double: roomCounts.double || 0,
      start_date: packageData.start_date,
      end_date: packageData.end_date,
      total_price: total_amount,
      special_request: "Null",
      payment_type: "Bank",
    };

    if (isRoomSelectionValid) {
      const bookingResponse = await createBookingRequest(
        payload.session_token,
        payload.partner_session_token,
        payload.huz_token,
        payload.adults,
        payload.child,
        payload.infants,
        payload.sharing,
        payload.quad,
        payload.triple,
        payload.double,
        payload.start_date,
        payload.end_date,
        payload.total_price,
        payload.special_request,
        payload.payment_type
      );
      // console.log("ata", bookingResponse.data);
      const packageDetails = {
        bookingResponse: bookingResponse.data,
        packageData,
        travellers,
        selectedRooms,
        totalRoomCost,
        total_amount,
        payable_amount,
        roomBreakDown,
        totalSelectedRooms,
        rooms,
        prices,
      };
      // Navigate to the next page and pass the validated data
      navigate(
        `/traveller-info-page/?packageId=${encodeURIComponent(huz_token)}`,
        {
          state: { packageDetails },
        }
      );
    }
  };

  if (loading)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <MessageDisplay message="Loading Detail..." type="loading" />
      </div>
    );
  if (!packageData)
    return (
      <div className="bg-white flex items-center justify-center h-screen">
        <p>No package data available</p>
      </div>
    );
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10 ">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Column */}
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={packageData} />
            <div className="lg:flex gap-4 mb-6">
              <TransportDetails data={packageData} />
              <AirlineDetails data={packageData} />
            </div>
            <PackageItinerary data={packageData} />

            <Itinerary data={packageData} />
            <RoomOptions
              data={packageData}
              travellers={travellers}
              selectedRooms={selectedRooms}
              setSelectedRooms={setSelectedRooms}
              prices={prices}
              rooms={rooms}
              totalSelectedRooms={totalSelectedRooms}
            />
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-[30%]">
            <BookingSummary
              data={packageData}
              travellers={travellers}
              selectedRooms={selectedRooms}
              prices={prices}
              rooms={rooms}
              handleClick={handleClick}
              totalSelectedRooms={totalSelectedRooms}
              roomBreakDown={roomBreakDown}
              totalRoomCost={totalRoomCost}
              total_amount={total_amount}
              payable_amount={payable_amount}
              isRoomSelectionValid={isRoomSelectionValid}
              handleTravellerChange={handleTravellerChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PackageDetailPage;
