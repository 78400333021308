import React from "react";
import envelopLogo from "../../../assets/signup/envelope.png";
import mobileLogo from "../../../assets/Payment/mobile.png";
import locationLogo from "../../../assets/Payment/location.png";
const BillingAddress = () => {
  return (
    <div className="text-secondaryBlue mt-8">
      <p className="text-[24px] font-600 mb-2">Billing Address </p>
      <div className="space-y-5 p-6 border-[1px] border-[#dfe4ea] bg-white rounded-md">
        <div className="md:flex w-full justify-between">
          <div className="xl:w-full justify-end">
            <div className=" space-y-5">
              <div>
                <div className="text-sm space-y-2">
                  <div className="md:flex gap-4">
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        First Name:
                      </label>

                      <input
                        type="text"
                        placeholder="Enter your First Name"
                        className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#DFE4EA]"
                      />
                    </div>
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Last Name:
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your Last Name"
                        className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#DFE4EA]"
                      />
                    </div>
                  </div>
                  <div className="md:flex gap-4">
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Mobile Number:
                      </label>
                      <div className="flex items-center px-4 border-[1px] border-[#DFE4EA] rounded-md">
                        <span>
                          <img
                            src={mobileLogo}
                            alt="Icon"
                            className="w-5 h-5"
                          />
                        </span>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          className="w-full p-2.5 text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Email:
                      </label>
                      <div className="flex items-center px-4 border-[1px] border-[#DFE4EA] rounded-md">
                        <span>
                          <img
                            src={envelopLogo}
                            alt="Icon"
                            className="w-5 h-5"
                          />
                        </span>
                        <input
                          type="text"
                          placeholder="Enter Email Address"
                          className="w-full p-2.5 text-sm "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2 flex flex-col mt-2">
                    <label className="text-[14px] font-semibold">
                      Address Line 1:
                    </label>
                    <div className="flex items-center px-4 border-[1px] border-[#DFE4EA] rounded-md">
                      <span>
                        <img
                          src={locationLogo}
                          alt="Icon"
                          className="w-5 h-5"
                        />
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Address"
                        className="w-full p-2.5 text-sm"
                      />
                    </div>
                  </div>
                  <div className="md:flex gap-4">
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Address Line 2:
                      </label>
                      <div className="flex items-center px-4 border-[1px] border-[#DFE4EA] rounded-md">
                        <span>
                          <img
                            src={locationLogo}
                            alt="Icon"
                            className="w-5 h-5"
                          />
                        </span>
                        <input
                          type="text"
                          placeholder="Enter Address"
                          className="w-full p-2.5 text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Enter City:
                      </label>
                      <input
                        type="text"
                        placeholder="Enter City Name"
                        className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#DFE4EA]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;
