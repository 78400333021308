import React from "react";
import airPlane from "../../../../assets/homepage/popularPackage/airFlight.png";
import hotels from "../../../../assets/homepage/popularPackage/hotel.png";
import transport from "../../../../assets/homepage/popularPackage/transport.png";
import saudiVisa from "../../../../assets/homepage/popularPackage/visa.png";
import insurance from "../../../../assets/Detail-Page/insurance.png"
const PackageItinerary = ({ data }) => {
  const {
    hotel_detail = [],
    is_visa_included,
    airline_detail = [],
    is_transport_included,
    is_insurance_included,
  } = data;
  const airline = airline_detail.length > 0 ? airline_detail[0] : null;
  let hotelLabel = "No Hotel";

  if (hotel_detail.length === 1) {
    hotelLabel = "1 Hotel"; // Show '1 Hotel' if there's only one hotel
  } else if (hotel_detail.length === 2) {
    hotelLabel = "2 Hotels"; // Show '2 Hotels' if there are exactly two hotels
  }
  return (
    <div className="bg-[#F3F4F6] px-2 py-[0.1px] rounded-md mb-4">
      {" "}
      <div className="flex items-center px-2 justify-between mt-4 mb-6 text-gray-500 text-xs">
        <div className="flex items-center gap-2">
          <img
            src={airPlane}
            alt="airPlane"
            className={`${
              airline.is__return_flight_included
                ? "text-brandGold"
                : "text-mediumBlue"
            } mb-1`}
          />
          <span className="text-xs text-gray-500">
            {airline.is_return_flight_included
              ? "Round Trip"
              : airline.is_flight_included
              ? "One Way"
              : "No Flight"}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <img src={hotels} alt="hotels" className="text-brandGold mb-1" />
          <span className="text-xs text-gray-500">{hotelLabel}</span>
        </div>
        <div className="flex items-center gap-2">
          <img
            src={transport}
            alt="transport"
            className={`${
              is_transport_included ? "text-brandGold" : "text-mediumBlue"
            } mb-1`}
          />
          <span className="text-xs text-gray-500">Transport</span>
        </div>
        <div className="flex items-center gap-2">
          <img
            src={saudiVisa}
            alt="visa"
            className={`${
              is_visa_included ? "text-brandGold" : "text-mediumBlue"
            } mb-1`}
          />
          <span className="text-xs text-gray-500">Visa</span>
        </div>
        <div className="flex items-center gap-2 ">
          <img
            src={insurance}
            alt="insurance"
            className={`${
              is_insurance_included ? "text-brandGold" : "text-mediumBlue"
            } mb-1`}
          />
          <span className="text-xs text-gray-500">Insurance</span>
        </div>
      </div>
    </div>
  );
};

export default PackageItinerary;
