import React from "react";

const PackageHeader = ({ data }) => {
  const {
    package_name,
    start_date,
    end_date,
    description,
    company_details: { company_name },
  } = data;

  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  return (
    <div className="mb-10">
      <h1 className="text-xl sm:text-2xl md:text-3xl font-bold leading-[48px] text-left">
        {package_name}
      </h1>
      <p className="text-blueForDate mt-[11px] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] font-[400] text-left">
        {formatDate(start_date)} to {formatDate(end_date)}
      </p>
      <p className="mt-[1px] text-brandGold font-[500] text-[12px] md:text-[14px] leading-[20px] md:leading-[22px] text-left">
        By <span className="font-semibold">{company_name}</span>
      </p>
      <p className="mt-[12px] text-primaryGray font-[400] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] text-justify">
        {description}
      </p>
    </div>
  );
};

export default PackageHeader;
