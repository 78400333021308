import React from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import bgImage from "../../../assets/homepage/discountSection/bgImage.png";
import bgshade from "../../../assets/bgShade.png";
const DiscountSectionArabic = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleNavigate = () => {
    navigate("/listing-page"); // Navigate to the desired route
  };

  return (
    <div
      className="w-full bg-cover bg-center"
      style={{
        backgroundImage: `
          url(${bgshade}), 
          url(${bgImage})`,
      }}
    >
      <div className="w-all-sections-width mx-auto py-s10 md:py-12 lg:py-24 space-y-6">
        <p className="text-brandGold text-[24px] font-600 text-right">
          خصم إضافي 10% على حجزك التالي عبر الإنترنت
        </p>
        <p className="h-48px text-white text-right">باقات العمرة المخصصة</p>
        <p className="text-[16px] font-400 text-white text-right">
          لقد قام مستشارونا ووكلاؤنا المحترفون بصياغة{" "}
          <br className="block xl:hidden" /> خط سير الرحلة المثالي بالنسبة لك.
          يخطط رحلتك مع <br className="hidden xl:block" />
          وكلائنا الخبراء
        </p>
        <div className="flex justify-end">
          <button
            className="mt-6 px-6 py-3 bg-brandGold font-normal text-[16px] text-secondaryBlue rounded-full hover:bg-opacity-90 w-auto"
            onClick={handleNavigate} // Attach the navigate function to the onClick event
          >
            عرض جميع الباقات
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscountSectionArabic;
