import React from "react";
import {
  FaShuttleVan,
  FaSnowflake,
  FaTv,
  FaWifi,
  FaArrowCircleUp,
  FaBath,
  FaToilet,
  FaSoap,
  FaToiletPaper,
} from "react-icons/fa";
import locationIcon from "../../../../assets/Detail-Page/location.svg";
import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../../../Utilities/UtilityFunctions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const Itinerary = ({ data }) => {
  const { hotel_detail, mecca_nights, madinah_nights, start_date } = data;

  const formatDateRange = (start, nights) => {
    const startDate = new Date(start);
    const endDate = new Date(start);
    endDate.setDate(startDate.getDate() + nights);

    const options = { weekday: "short", day: "numeric", month: "short" };
    const formattedStart = startDate.toLocaleDateString("en-US", options);
    const formattedEnd = endDate.toLocaleDateString("en-US", options);

    return `${formattedStart} to ${formattedEnd}`;
  };

  const meccaDates = formatDateRange(start_date, mecca_nights);
  const madinahStart = new Date(start_date);
  madinahStart.setDate(madinahStart.getDate() + mecca_nights);
  const madinahDates = formatDateRange(madinahStart, madinah_nights);

  const amenitiesIcons = {
    is_shuttle_services_included: {
      icon: <FaShuttleVan />,
      label: "Shuttle Service",
    },
    is_air_condition: { icon: <FaSnowflake />, label: "Air Conditioning" },
    is_television: { icon: <FaTv />, label: "Television" },
    is_wifi: { icon: <FaWifi />, label: "Free WiFi" },
    is_elevator: { icon: <FaArrowCircleUp />, label: "Elevator" },
    is_attach_bathroom: { icon: <FaBath />, label: "Attached Bathroom" },
    is_washroom_amenities: { icon: <FaSoap />, label: "Washroom Amenities" },
    is_english_toilet: { icon: <FaToilet />, label: "English Toilet" },
    is_indian_toilet: { icon: <FaToiletPaper />, label: "Indian Toilet" },
    is_laundry: { icon: <FaSoap />, label: "Laundry Service" },
  };

  const HotelCard = ({ hotel, hotelData }) => {
    const hotelImages = getHotelImages(hotel.hotel_name, hotelData);

    return (
      <div className="bg-white p-4 md:p-6 rounded-[12px] shadow-md border-[1px] border-custom-gray mb-6">
        <h3 className="text-[18px] md:text-[20px] font-[600] leading-[24px]">
          {hotel.hotel_name}
        </h3>
        <p className="flex items-center text-brandGold">
          {"⭐".repeat(parseInt(hotel.hotel_rating)) || "⭐"}{" "}
        </p>
        <p className="text-black font-[500] text-[14px] leading-[22px] mt-1">
          {hotel.hotel_distance} {hotel.distance_type} from Kaaba
        </p>
        <p className="text-[12px] md:text-[14px] font-[400] leading-[22px] text-primaryGray mt-2">
          {hotel.hotel_description ||
            `Stay at ${hotel.hotel_name}, featuring ${
              hotel.is_air_condition ? "air-conditioned rooms, " : ""
            }${hotel.is_wifi ? "free WiFi, " : ""}${
              hotel.is_elevator ? "elevators, " : ""
            } and other amenities.`}
        </p>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {Object.keys(amenitiesIcons).map((key) =>
            hotel[key] ? (
              <div key={key} className="flex items-center space-x-2">
                <span className="text-brandGold text-sm md:text-lg">
                  {amenitiesIcons[key].icon}
                </span>
                <p className="text-secondaryGray text-[12px] font-[500] leading-[20px]">
                  {amenitiesIcons[key].label}
                </p>
              </div>
            ) : null
          )}
        </div>
        <p className="text-sm text-gray-600 mt-4 flex items-center space-x-2">
          <img
            src={locationIcon}
            alt="Location Icon"
            className="w-3 md:w-4 h-3 md:h-4"
          />
          <span>{hotel.hotel_address || "Address not available"}</span>
        </p>
        {/* Hotel Images */}
        {hotelImages.length > 0 && (
          <div className="mt-4 ">
            {hotelImages.length <= 3 ? (
              <div className={`grid grid-cols-${hotelImages.length} gap-4`}>
                {hotelImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${hotel.hotel_name} image ${index + 1}`}
                    className="rounded-[8px] shadow-sm"
                    style={{
                      width: "100%",
                      maxWidth: "230px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ))}
              </div>
            ) : (
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                  640: { slidesPerView: 2 },
                  1024: { slidesPerView: 3 },
                }}
                loop
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={true}
                navigation={false}
                modules={[Autoplay]}
              >
                {hotelImages.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image}
                      alt={`${hotel.hotel_name} slider image ${index + 1}`}
                      className="rounded-[8px] shadow-sm"
                      style={{
                        width: "100%",
                        // maxWidth: "230px",
                        height: "173px",
                        objectFit: "cover",
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-10">
      {/* Makkah Section */}
      <div className="mb-8">
        <h2 className="text-[20px] md:text-[24px] font-[600] leading-[28px] md:leading-[30px] mb-4 ">
          Makkah {mecca_nights} Nights{" "}
          <span className="text-[12px] ml-2 font-[500]">{meccaDates}</span>
        </h2>
        {hotel_detail
          .filter((hotel) => hotel.hotel_city.toLowerCase() === "mecca")
          .map((hotel, index) => (
            <HotelCard key={index} hotel={hotel} hotelData={makkahHotels} />
          ))}
      </div>
      {/* Madinah Section */}
      <div>
        <h2 className="text-[20px] md:text-[24px] font-[600] leading-[28px] md:leading-[30px] mb-4">
          Madinah {madinah_nights} Nights{" "}
          <span className="text-[12px] ml-2 font-[500]">{madinahDates}</span>
        </h2>
        {hotel_detail
          .filter((hotel) => hotel.hotel_city.toLowerCase() === "madinah")
          .map((hotel, index) => (
            <HotelCard key={index} hotel={hotel} hotelData={madinahHotels} />
          ))}
      </div>
    </div>
  );
};

export default Itinerary;
