import React, { useState } from "react";
import {
  HeroArabic,
  DiscountSectionArabic,
  BrowseByPeopleArabic,
  PopularPackagesArabic,
  ServicesSectionArabic,
  AppDownloadArabic,
  FooterArabic,
  HeaderArabic,
} from "../../";
import heroimg from "../../assets/heroImage.png";

const HomePageArabic = () => {
  // State to control the margin
  const [popularPackagesStatus, setPopularPackagesStatus] = useState("loading");

  // Determine the margin class based on the status
  const getMarginClass = () => {
    if (popularPackagesStatus === "success") return "lg:mb-[330px]";
    return "lg:pb-[60px]";
  };

  return (
    <main className="bg-white">
      <div className={`${getMarginClass()}`}>
        <div
          className="w-full h-[650px] lg:h-[950px] bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(0deg, 
            rgba(12, 42, 53, 1) 0%, 
            rgba(24, 77, 97, 0.5) 60%, 
            rgba(12, 42, 53, 1) 100%), 
            url(${heroimg})`,
          }}
        >
          <HeaderArabic />
          <HeroArabic />
        </div>

        {/* Pass a callback to update the status */}
        <PopularPackagesArabic onStatusChange={setPopularPackagesStatus} />
      </div>
      <div className="">
        <ServicesSectionArabic />
        <DiscountSectionArabic />
        <BrowseByPeopleArabic />
        <AppDownloadArabic />
        <FooterArabic />
      </div>
    </main>
  );
};

export default HomePageArabic;
