import React, { useState } from "react";
import {
  BookingSummary,
  Footer,
  Header,
  PackageHeader,
  PassportInfo,
} from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPassportValidity } from "../../api/apiService";
import dayjs from "dayjs";
const TravellerInfoPage = () => {
  const location = useLocation();
  const { packageDetails } = location.state || {};

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const {
    bookingResponse,
    packageData,
    travellers,
    selectedRooms,
    totalRoomCost,
    total_amount,
    payable_amount,
    roomBreakDown,
    totalSelectedRooms,
    rooms,
    prices,
  } = packageDetails;
  const [passports, setPassports] = useState(
    Array.from({ length: travellers }).map(() => ({
      firstName: "",
      lastName: "",
      dob: "",
      passportNumber: "",
      nationality: "",
      expiryDate: "",
    }))
  );
  const [errorMessages, setErrorMessages] = useState(
    Array(travellers).fill("")
  );
  if (!packageDetails) return <p>No package data available</p>;

  const checkRoomSelection = () => {
    let totalCapacity = 0;
    Object.keys(selectedRooms).forEach((roomKey) => {
      const room = rooms.find((r) => r.key === roomKey);
      if (room) {
        totalCapacity += room.sleeps * selectedRooms[roomKey];
      }
    });
    return totalCapacity >= travellers; // Ensure room capacity is greater than or equal to number of travellers
  };
  const isRoomSelectionValid = checkRoomSelection();
  const passportRegex = /^[A-Z0-9]{6,9}$/;
  const returnDate = packageData.end_date;
  const isExpiryDateValid = (expiryDate) => {
    const passportExpiryDate = dayjs(expiryDate);
    const returnDatePlus6Months = dayjs(returnDate).add(6, "month");
    return passportExpiryDate.isAfter(returnDatePlus6Months);
  };
  const validateAllTravelers = () => {
    const updatedErrorMessages = [...errorMessages];
    let isValid = true;

    passports.forEach((passport, index) => {
      if (!passport.passportNumber) {
        updatedErrorMessages[index] = `Passport number for traveler ${
          index + 1
        } cannot be empty.`;
        isValid = false;
      } else if (!passportRegex.test(passport.passportNumber)) {
        updatedErrorMessages[index] = `Invalid passport number for traveler ${
          index + 1
        }. Use 6-9 alphanumeric characters.`;
        isValid = false;
      } else if (!passport.nationality) {
        updatedErrorMessages[index] = `Country issued for traveler ${
          index + 1
        } cannot be empty.`;
        isValid = false;
      } else if (!isExpiryDateValid(passport.expiryDate)) {
        updatedErrorMessages[index] = `Passport for traveler ${
          index + 1
        } must be valid for at least 6 months after the return date.`;
        isValid = false;
      } else {
        updatedErrorMessages[index] = ""; // Clear error if valid
      }
    });

    setErrorMessages(updatedErrorMessages);
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateAllTravelers()) {
      setIsSubmitting(false);
      return;
    }

    try {
      for (let passport of passports) {
        const payload = {
          session_token: bookingResponse.user_session_token,
          booking_number: bookingResponse.booking_number,
          first_name: passport.firstName,
          last_name: passport.lastName,
          passport_number: passport.passportNumber,
          passport_country: passport.nationality,
          date_of_birth: passport.dob,
          expiry_date: passport.expiryDate,
        };

        const response = await checkPassportValidity(payload);

        if (response.error) {
          console.error(
            `Error validating passport: ${passport.passportNumber}`,
            response.message
          );
          setIsSubmitting(false);
          return;
        }
        const packageDetails = {
          bookingResponse: bookingResponse,
          packageData,
          travellers,
          selectedRooms,
          totalRoomCost,
          total_amount,
          payable_amount,
          roomBreakDown,
          totalSelectedRooms,
          rooms,
          prices,
        };
        navigate(
          `/payment-page/?bookingNumber=${encodeURIComponent(
            response.booking_number
          )}`,
          {
            state: { packageDetails },
          }
        );
      }
    } catch (error) {
      console.error("An error occurred while submitting passports:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10 ">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Column */}
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={packageData} />
            <PassportInfo
              bookingResponse={bookingResponse}
              packageData={packageData}
              travellers={bookingResponse.adults}
              totalSelectedRooms={totalSelectedRooms}
              rooms={rooms}
              prices={prices}
              totalRoomCost={totalRoomCost}
              total_amount={bookingResponse.total_price}
              payable_amount={payable_amount}
              roomBreakDown={roomBreakDown}
              selectedRooms={selectedRooms}
              passports={passports}
              setPassports={setPassports}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              errorMessages={errorMessages}
            />
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-[30%]">
            <BookingSummary
              data={packageData}
              travellers={travellers}
              selectedRooms={selectedRooms}
              prices={prices}
              rooms={rooms}
              handleSubmit={handleSubmit}
              totalSelectedRooms={totalSelectedRooms}
              roomBreakDown={roomBreakDown}
              totalRoomCost={totalRoomCost}
              total_amount={total_amount}
              payable_amount={payable_amount}
              isRoomSelectionValid={isRoomSelectionValid}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TravellerInfoPage;
