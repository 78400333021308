import React from "react";
import {
  BookingInfo,
  Footer,
  Header,
  PackageHeader,
} from "../../../";
import banner from "../../../assets/Detail-Page/banner.png";
import { useLocation } from "react-router-dom";
const BookingInfoPage = () => {
  const location = useLocation();
  const { packageDetails, bookingResponse } = location.state || {};
  const {
    packageData,
    travellers,
    selectedRooms,
    totalRoomCost,
    payable_amount,
    roomBreakDown,
    totalSelectedRooms,
    rooms,
    prices,
  } = packageDetails;
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10 ">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div>
          {/* Left Column */}
          <div>
            <PackageHeader data={packageData} />
            <BookingInfo
              bookingResponse={bookingResponse}
              travellers={travellers}
              selectedRooms={selectedRooms}
              prices={prices}
              rooms={rooms}
              totalSelectedRooms={totalSelectedRooms}
              roomBreakDown={roomBreakDown}
              totalRoomCost={totalRoomCost}
              payable_amount={payable_amount}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingInfoPage;
