import React, { useState } from "react";
import country_data from "./country_data.json";
import { checkUserExistence, sendOtp } from "../../api/AuthApi";
import { ButtonsLoader, Otp } from "../..";
const Login = ({ closeModal, openSignUpModal }) => {
  const [formData, setFormData] = useState({ phone_number: "", country: "PK" });
  const [errors, setErrors] = useState({ phone_number: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [countrycode, setCountryCode] = useState(
    country_data.find((item) => item.code === "PK").dial_code
  );
  const [showOtp, setShowOtp] = useState(false);
  const handleCountryCodeChange = (e) => setCountryCode(e.target.value);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^[0-9]{0,10}$/.test(value)) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (value)
        setErrors((prevErrors) => ({ ...prevErrors, phone_number: "" }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ phone_number: "" });
    setApiError("");
    setIsLoading(true);
    if (!formData.phone_number) {
      setErrors({ phone_number: "Phone Number is required" });
      setIsLoading(false);
      return;
    }
    try {
      const number = countrycode + formData.phone_number;
      const response = await checkUserExistence(number);
      if (
        response?.data?.message ===
        "User with this phone number does not exist."
      ) {
        setShowOtp(false);
        setApiError(
          "User with this phone number does not exist. Please sign up first."
        );
      }
      if (
        response?.data?.message !==
        "User with this phone number does not exist."
      ) {
        localStorage.setItem("Login-User-Profile", JSON.stringify(response));
        localStorage.setItem("User-Phone-Number", JSON.stringify(number));
        await sendOtp(number);
        setShowOtp(true);
      }
    } catch (error) {
      setApiError(
        error.response?.status === 400
          ? error.response.data.message || "An error occurred"
          : "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="text-secondaryBlue">
      {showOtp ? (
        <Otp closeModal={closeModal} />
      ) : (
        <div className="bg-white border-[1px] border-[#DFE4EA] p-6 rounded-md">
          <h2 className="text-[24px] font-600">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className=" space-y-4">
              <div className=" space-y-2 w-full">
                <label htmlFor="phone_number" className="text-[16px] font-500">
                  Phone Number
                </label>
                <div className="flex items-center border-[1px] border-[#DFE4EA] rounded-md">
                  <select
                    className="border-r-[1px] w-1/4 border-[#DFE4EA] p-2.5 rounded-l-md text-sm text-black font-normal focus:outline-none focus:ring-0"
                    value={countrycode}
                    onChange={handleCountryCodeChange}
                  >
                    {country_data.map((items) => (
                      <option
                        key={items.code}
                        value={items.dial_code}
                        className="text-sm text-black font-normal"
                      >
                        {items.name} {items.dial_code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    id="phone_number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    className="flex-1 p-2 text-[16px] outline-none font-500 rounded-r-md"
                  />
                </div>
                {errors.phone_number && (
                  <p className="text-red-500 text-sm">{errors.phone_number}</p>
                )}
                {apiError && <p className="text-red-500 text-sm">{apiError}</p>}
              </div>
            </div>
            <div className="mt-6 flex-row text-center space-y-2">
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="text-[16px] w-full font-500 bg-brandGold p-4 rounded-[30px] py-2 text-white"
                >
                  {isLoading ? <ButtonsLoader type="loading" /> : "Login"}
                </button>
              </div>
              <div className="mt-4 md:mt-0 text-[16px] font-600">
                <p className="text-brandGold ">Forget Pasword?</p>
                <p className="text-primaryGray ">
                  Not a member yet?{" "}
                  <span
                    onClick={openSignUpModal}
                    className="text-brandGold cursor-pointer"
                  >
                    Sign Up
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
