import React, { useState, useEffect } from "react";
import {
  Header,
  AppDownload,
  Footer,
  SortingDiv,
  FiltersComponent,
  PackageListing,
} from "../../";
import { useSearchParams } from "react-router-dom";
import {
  getPackageDetailByCityAndDate,
  getShortPackageDetails,
} from "../../api/apiService";

const ListingPage = () => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    value: "Recommended",
    label: "Recommended",
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const [searchParams] = useSearchParams();

  // Add state to track if filters should be visible
  const [showFilters, setShowFilters] = useState(
    searchParams.get("departureLocation") ||
      searchParams.get("departureDate") ||
      ""
  );

  // Function to format the date to the desired format
  const formatDate = (date) => {
    if (!date) return null;
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return `${utcDate.getUTCFullYear()}-${(utcDate.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${utcDate.getUTCDate().toString().padStart(2, "0")}`;
  };

  // Fetch packages from API
  const fetchPackages = async (filters = {}) => {
    setLoading(true);
    setError(null);

    const type = searchParams.get("type") || "Umrah";
    const package_type = type;
    const start_date = filters.departureDate
      ? formatDate(new Date(filters.departureDate))
      : searchParams.get("departureDate");
    const flight_from =
      filters.departureCity?.value ||
      searchParams.get("departureLocation") ||
      "";

    try {
      let result;

      // Check if departureLocation or departureDate is null or empty
      if (!flight_from || !start_date) {
        // If either is missing, call getShortPackageDetails API
        result = await getShortPackageDetails({
          package_type,
        });
      } else {
        // If both are present, call getPackageDetailByCityAndDate API
        result = await getPackageDetailByCityAndDate({
          package_type,
          start_date,
          flight_from,
        });
      }

      if (result) {
        setPackages(result);
      } else {
        throw new Error("No packages found or invalid response");
      }
    } catch (err) {
      setError(`Error fetching packages: ${err.message || "Unknown error"}`);
      console.error("Error fetching packages:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Update showFilters when URL params change
    setShowFilters(
      !!searchParams.get("departureLocation") ||
        !!searchParams.get("departureDate") ||
        !searchParams.get("departureLocation") ||
        !searchParams.get("departureDate")
    );

    // Fetch packages when filters or pagination changes
    fetchPackages(filters);
  }, [filters.departureCity, filters.departureDate, searchParams]);

  //   // Apply local filters (like duration, room type) without triggering the API
  useEffect(() => {
    if (!packages.length) return;

    let filtered = [...packages];

    if (filters.umrahDuration) {
      filtered = filtered.filter(
        (pkg) =>
          pkg.mecca_nights + pkg.madinah_nights === filters.umrahDuration.value
      );
    }
    if (filters.airline) {
      filtered = filtered.filter(
        (pkg) =>
          pkg &&
          pkg.airline_detail &&
          pkg.airline_detail.airline_name === filters.airline.value
      );
    }

    if (filters.hotelDistance) {
      filtered = filtered.filter((pkg) =>
        pkg.hotel_detail.some(
          (hotel) => hotel.hotel_distance === filters.hotelDistance
        )
      );
    }

    if (filters.tripDetails) {
      Object.keys(filters.tripDetails).forEach((key) => {
        if (filters.tripDetails[key]) {
          filtered = filtered.filter((pkg) => pkg[`is_${key}`]);
        }
      });
    }
    // Sorting by price (if that's still applicable)
    if (sortOrder.value === "low-to-high") {
      filtered.sort((a, b) => a.cost_for_sharing - b.cost_for_sharing);
    } else if (sortOrder.value === "high-to-low") {
      filtered.sort((a, b) => b.cost_for_sharing - a.cost_for_sharing);
    }

    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = filtered.slice(
      startIndex,
      startIndex + pagination.pageSize
    );

    setFilteredPackages(paginatedData);
  }, [filters, sortOrder, packages, pagination]);

  const handleFilterChange = (updatedFilters) => setFilters(updatedFilters);

  const handlePageChange = (direction) => {
    setPagination((prev) => {
      const totalPages = Math.ceil(packages.length / prev.pageSize);
      const newPage =
        direction === "next"
          ? Math.min(prev.currentPage + 1, totalPages)
          : Math.max(prev.currentPage - 1, 1);

      return {
        ...prev,
        currentPage: newPage,
      };
    });
  };

  return (
    <div>
      <Header />
      <main className="w-all-sections-width mx-auto mb-s10 md:mb-m10 lg:mb-l14">
        <SortingDiv sortOrder={sortOrder} setSortOrder={setSortOrder} />
        <div className="flex flex-col lg:flex-row gap-6 mt-6">
          <button
            onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            className="lg:hidden px-4 py-2 bg-brandGold text-white rounded-md text-sm hover:bg-opacity-90"
          >
            {isFiltersVisible ? "Close Filters" : "Show Filters"}
          </button>
          {isFiltersVisible && showFilters && (
            <aside className="w-full lg:hidden">
              <FiltersComponent
                onFilterChange={handleFilterChange}
                clearFilters={() => setFilters({})}
                filters={filters}
                setFilters={setFilters}
              />
            </aside>
          )}
          {showFilters && (
            <aside className="hidden lg:block lg:w-[35%]">
              <FiltersComponent
                onFilterChange={handleFilterChange}
                clearFilters={() => setFilters({})}
                filters={filters}
                setFilters={setFilters}
              />
            </aside>
          )}
          <PackageListing
            packages={filteredPackages}
            loading={loading}
            error={error}
            onPageChange={handlePageChange}
            pagination={pagination}
            filters={filters}
          />
        </div>
      </main>
      <AppDownload />
      <Footer />
    </div>
  );
};

export default ListingPage;
