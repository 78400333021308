import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../../Utilities/UtilityFunctions";
import { useNavigate } from "react-router-dom";

const PackageCard = ({ data, filters }) => {
  const {
    huz_token,
    package_name,
    start_date,
    end_date,
    company_details,
    hotel_detail,
    mecca_nights,
    madinah_nights,
    cost_for_sharing,
    airline_detail = [],
  } = data;

  const [initialTravellers, setInitialTravellers] = useState(1);
  const navigate = useNavigate();
  const handleTravellerChange = (type) => {
    setInitialTravellers((prev) => {
      if (type === "increase") return prev + 1;
      if (type === "decrease" && prev > 1) return prev - 1;
      return prev;
    });
  };
  const num_days = mecca_nights + madinah_nights;
  const hotelImages = hotel_detail
    .map((hotel) =>
      getHotelImages(hotel.hotel_name, [...makkahHotels, ...madinahHotels])
    )
    .flat();
  const handleClick = () => {
    navigate(`/package-detail-page/?PackageId=${huz_token}`, {
      state: {
        huz_token,
        filters,
        initialTravellers,
      },
    });
  };
  const total_cost = initialTravellers * cost_for_sharing;
  const departure_city = airline_detail[0].flight_from;
  console.log("hfhf", departure_city);
  return (
    <div className="bg-white border-[1px] border-custom-gray rounded-[8px] p-4 gap-4 hover:shadow-xl transition-transform transform hover:scale-[1.02] duration-300 flex flex-col lg:flex-row">
      {/* Column 1: Hotel Images */}
      <div className="col1 w-full lg:w-[150px] h-40 lg:max-h-[150px] overflow-hidden rounded-md">
        {hotelImages.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            pagination={false}
            navigation={false}
            modules={[Autoplay]}
            className="w-full h-full"
          >
            {hotelImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={package_name}
                  className="w-full h-full object-cover rounded-md"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <img
            src="https://via.placeholder.com/150"
            alt="Default hotel"
            className="w-full h-full object-cover rounded-md"
          />
        )}
      </div>

      {/* Column 2: Package Details */}
      <div className="col2 flex flex-col flex-grow">
        {/* Row 1: Basic Details */}
        <div className="c2-r1 mb-4 cursor-pointer" onClick={handleClick}>
          <h3 className="font-[600] text-xl">{package_name}</h3>
          <p className="text-base font-[400] text-primaryGray">
            {new Date(start_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            to{" "}
            {new Date(end_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="text-brandGold font-[500] text-sm mt-1">
            By {company_details?.company_name || "N/A"}
          </p>
        </div>

        {/* Row 2: Two Columns */}
        <div className="c2-r2 grid md:grid-cols-3 lg:flex xl:grid xl:grid-cols-3 gap-4 items-center">
          {/* Column 1: Traveller Selection */}
          <div className="c2-r2-c1">
            <p className="text-xs font-[500] text-primaryGray mb-2">
              TRAVELLERS
            </p>
            <div className="flex items-center">
              <button
                className="border border-lightGray lg:px-1.5 xl:px-3 px-3 py-1 hover:bg-gray-200 text-base font-[500] hover:scale-110 transition-transform duration-200"
                onClick={() => handleTravellerChange("decrease")}
              >
                -
              </button>
              <span className="border-y border-lightGray lg:px-3 xl:px-5 px-5 py-1 text-base font-[500] hover:bg-gray-200 hover:scale-110 transition-transform duration-200">
                {initialTravellers}
              </span>
              <button
                className="border border-lightGray lg:px-1.5 xl:px-3 px-3 py-1 text-base font-[500] hover:bg-gray-200 hover:scale-110 transition-transform duration-200"
                onClick={() => handleTravellerChange("increase")}
              >
                +
              </button>
            </div>
          </div>
          <div className="text-center">
            <p className="text-primaryGray font-[500] text-xs">
              Total Number of Nights
            </p>
            <p className="text-lg text-brandGold font-semibold">{num_days}</p>
          </div>
          {/* Column 2: Pricing */}
          <div className="c2-r2-c2 flex flex-col items-end justify-end text-end">
            <p className="text-primaryGray font-[500] text-[10.9px]">
              Direct flight from {departure_city}
            </p>
            <p className="text-primaryGray font-[500] text-xs">Starting from</p>
            <p className="text-brandGold ml-1 text-lg font-[600] transition-colors hover:text-yellow-600">
              PKR {total_cost.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
