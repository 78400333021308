import React, { useEffect } from "react";
import { Header, Footer } from "../../..";
import evisa from "../../../assets/visa/evisabackground.png";
import passportConfirmImg from "../../../assets/visa/passportConfirm.svg";
import whatsappImg from "../../../assets/visa/whatsapp.svg";
import tickImg from "../../../assets/visa/tick.svg";
const ConfirmVisa = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const backgroundImageStyle = {
    backgroundImage: `url(${evisa})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const importantInfo = [
    {
      title: "Terms and Conditions:",
      content: (
        <>
          Please ensure you read the entire{" "}
          <a href="#" className="text-brandGold">
            Terms & Conditions
          </a>
        </>
      ),
    },
    {
      title: "Send Us a Message:",
      content: (
        <>
          Instantly connect with us for all your queries via WhatsApp or chat.
        </>
      ),
    },
    {
      title: "Processing Time:",
      content: (
        <>
          Upon submission of your documents, please anticipate a processing time
          of 48 hours for your visa.
        </>
      ),
    },
    {
      title: "Need Help?:",
      content: (
        <>
          Call{" "}
          <a href="tel:+923005295917" className="text-brandGold">
            +92 300 5295917
          </a>{" "}
          or WhatsApp{" "}
          <a href="https://wa.me/923005295917" className="text-brandGold">
            +92 300 5295917
          </a>
          .
        </>
      ),
    },
  ];

  const shareInfo = [
    {
      image: passportConfirmImg,
      title: "Share with your loved ones!",
      description:
        "Encourage your loved ones to apply for visas through sastaticket!",
      buttonText: "Share Now",
    },
    {
      image: whatsappImg,
      title: "Want to know more?",
      description: "Contact us on our WhatsApp for more assistance.",
      buttonText: "+92 300 5295917",
    },
  ];

  return (
    <div className="bg-[#f5f5f5] text-secondaryBlue">
      <Header />
      <div className=" w-all-sections-width mx-auto">
        <div
          className="flex gap-4 p-8 px-0 items-center"
          style={backgroundImageStyle}
        >
          <img src={tickImg} alt="Tick" />
          <div className="space-y-1.5">
            <p className="text-[20px] font-semibold">
              Visa Application Received!
            </p>
            <p className="text-[16px] font-semibold opacity-80">
              Thank you!
            </p>
          </div>
        </div>
        <p className="text-[16px] font-semibold">
          You've initiated your Umrah journey. Our Visa team will be in touch
          with you shortly to collect the necessary details.
        </p>
        <div>
          <h3 className="p-4 px-0 font-semibold text-[16px] mb-2">
            Important Information
          </h3>
          <ul className="p-4 md:px-4 lg:px-8 bg-white border rounded-lg list-disc list-inside space-y-2">
            {importantInfo.map((info, index) => (
              <li key={index} className="text-[14px]">
                <span className="font-semibold text-[16px]">{info.title}</span>{" "}
                {info.content}
              </li>
            ))}
          </ul>
        </div>
        <div className="my-10 space-y-4 xl:space-y-0 xl:flex gap-4 justify-between text-white">
          {shareInfo.map((info, index) => (
            <div
              key={index}
              className="bg-brandGold bg-opacity-10 px-8 py-4 rounded-lg md:flex items-center space-y-4 md:space-y-0 justify-between md:space-x-4"
            >
              <div className="md:flex justify-between items-center gap-4 space-y-4 md:space-y-0">
                <img src={info.image} alt={info.title} className="h-12 w-12" />
                <div className="space-y-1.5">
                  <p className="text-[16px] font-semibold">
                    {info.title}
                  </p>
                  <p className="text-[14px] ">
                    {info.description}
                  </p>
                </div>
              </div>
              <button className="text-sm text-white p-1.5 px-4 rounded-md border border-white">
                {info.buttonText}
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmVisa;
