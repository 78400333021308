import React, { useState } from "react";
import { Signup } from "../../api/AuthApi";
import userLogo from "../../assets/signup/user.png";
import envelopLogo from "../../assets/signup/envelope.png";
import country_data from "../Login/country_data.json";
import { ButtonsLoader, Otp } from "../../";
const SignUp = ({ closeModal, openLoginModal }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone_number: "",
    userType: "user",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [countrycode, setCountryCode] = useState(
    country_data.find((item) => item.code === "PK").dial_code
  );
  const [showOtp, setShowOtp] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^[0-9]{0,10}$/.test(value)) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (value)
        setErrors((prevErrors) => ({ ...prevErrors, phone_number: "" }));
    }
  };
  const handleCountryCodeChange = (e) => setCountryCode(e.target.value);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error for the current field
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, phone_number } = formData;
    const newErrors = {};
    if (!fullName) newErrors.fullName = "Full name is required";
    if (!email) {
      newErrors.email = "Email is required";
    }
    if (!phone_number) {
      newErrors.phone_number = "Phone Number is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Enter a valid email";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsLoading(true);
    setApiError("");
    try {
      const number = countrycode + formData.phone_number;
      const response = await Signup({
        phone_number: number, // Ensure phone_number is a string
        name: fullName, // Ensure fullName is a string
        email, // Ensure email is a string
        user_type: formData.userType, // userType is a string
      });
      if (
        response?.data?.message ===
        "User with this phone number already exists."
      ) {
        setApiError(
          "User with this phone number already exists. Please Log in."
        );
        return; // Stop further execution if the user already exists
      }
      localStorage.setItem("Login-User-Profile", JSON.stringify(response.data));
      localStorage.setItem("User-Phone-Number", JSON.stringify(phone_number));
      setShowOtp(true);
    } catch (e) {
      console.error("Error", e);
      setApiError(e.response?.data?.message || "An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  return (
    <div className="text-secondaryBlue">
      {showOtp ? (
        <Otp closeModal={closeModal} />
      ) : (
        <div className="bg-white p-6 py-6 rounded-md">
          <h2 className="text-[24px] font-600 text-secondaryBlue">Sign up</h2>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4 gap-6 mt-4">
              <div className="w-full space-y-2">
                <label>Full Name</label>
                <div className="flex items-center px-4 border-[1px] border-[#DFE4EA] rounded-md">
                  {/* Icon */}
                  <span>
                    <img src={userLogo} alt="Icon" className="w-5 h-5" />
                  </span>

                  {/* Input */}
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    placeholder="Enter full name"
                    className="p-2 text-[16px] outline-none font-500 w-full"
                  />
                </div>
                {errors.fullName && (
                  <p className="text-red-500 text-sm">{errors.fullName}</p>
                )}
              </div>
              <div className="w-full space-y-2">
                <label>Email</label>
                <div className="flex items-center px-4 border-[1px] border-[#DFE4EA] rounded-md">
                  <span>
                    <img src={envelopLogo} alt="Icon" className="w-5 h-5" />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter email address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="p-2 text-[16px] outline-none font-500 w-full"
                  />
                </div>
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
            </div>

            <div className="space-y-4 mt-4">
              <div className=" space-y-2 w-full">
                <label htmlFor="phone_number" className="text-[16px] font-500">
                  Phone Number
                </label>
                <div className="flex items-center border-[1px] border-[#DFE4EA] rounded-md">
                  <select
                    className="border-r-[1px] w-1/4 border-[#DFE4EA] p-2.5 rounded-l-md text-sm text-black font-normal focus:outline-none focus:ring-0"
                    value={countrycode}
                    onChange={handleCountryCodeChange}
                  >
                    {country_data.map((items) => (
                      <option
                        key={items.code}
                        value={items.dial_code}
                        className="text-sm text-black font-normal"
                      >
                        {items.name} {items.dial_code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    id="phone_number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    className="flex-1 p-2 text-[16px] outline-none font-500 rounded-r-md"
                  />
                </div>
                {errors.phone_number && (
                  <p className="text-red-500 text-sm">{errors.phone_number}</p>
                )}
                {apiError && (
                  <p className="text-red-500 text-center text-sm mt-4">
                    {apiError}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-6 text-center space-y-2">
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`text-[16px] w-full font-500 bg-brandGold p-4 rounded-[30px] py-2 text-white ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isLoading ? (
                    <ButtonsLoader message="" type="loading" />
                  ) : (
                    "Create Account"
                  )}
                </button>
              </div>
              <div>
                <p className="text-[16px] font-400 text-[#A8A8AA]">
                  Already a member?{" "}
                  <span
                    className="text-brandGold cursor-pointer"
                    onClick={openLoginModal}
                  >
                    Log in
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SignUp;
