// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  About,
  AllBookings,
  BookingInfoPage,
  BrowsingTipsPage,
  ConfirmVisa,
  Contactus,
  HajjGuidance,
  HomePage,
  HomePageArabic,
  ListingPage,
  PackageDetailPage,
  PaymentPage,
  TravelEssential,
  TravellerInfoPage,
  UmrahGuidance,
  UnderDevelopment,
  VisaServices,
} from "./"; // Ensure you have imported your pages correctly
import ScrollToTop from "./Utilities/UtilityFunctions.js"; // Import ScrollToTop utility
import "./styles/globals.css"; // Ensure global styles are imported
import AuthContextProvider from "./context/AuthContextProvider.js";
import { ModalProvider } from "./context/ModalContext.js";

const App = () => {
  return (
    <AuthContextProvider>
      <ModalProvider>
        <Router>
          <ScrollToTop /> {/* Ensure this is inside the Router */}
          <div>
            <Routes>
              {/* Home Page Route */}
              <Route path="/" element={<HomePage />} />
              {/* Listing Page Route */}
              <Route path="/listing-page" element={<ListingPage />} />
              <Route
                path="/package-detail-page"
                element={<PackageDetailPage />}
              />
              <Route
                path="/traveller-info-page"
                element={<TravellerInfoPage />}
              />
              <Route path="/payment-page" element={<PaymentPage />} />
              <Route path="/booking-info" element={<BookingInfoPage />} />
              <Route path="/development-phase" element={<UnderDevelopment />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contactus />} />
              <Route path="/Hajj-Guidance" element={<HajjGuidance />} />
              <Route path="/Umrah-Guidance" element={<UmrahGuidance />} />
              <Route path="/browse-tips" element={<BrowsingTipsPage />} />
              <Route path="/travel-essential" element={<TravelEssential />} />
              <Route path="/visa-services" element={<VisaServices />} />
              <Route
                path="/evisa-services/confirmation-process"
                element={<ConfirmVisa />}
              />
              <Route path="/all-bookings" element={<AllBookings />} />
              {/* Arabic Page */}
              <Route path="/Home-Page-Arabic" element={<HomePageArabic />} />
            </Routes>
          </div>
        </Router>
      </ModalProvider>
    </AuthContextProvider>
  );
};

export default App;
