import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BookingSummary,
  Footer,
  Header,
  PackageHeader,
  PaymentOption,
} from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import {
  createBookingRequestbyTransactionNumber,
  createBookingRequestbyTransactionPhoto,
} from "../../api/apiService";
import AuthContext from "../../context/AuthContext";
const PaymentPage = () => {
  const location = useLocation();
  const { packageDetails } = location.state || {};
  const {
    bookingResponse,
    packageData,
    travellers,
    selectedRooms,
    totalRoomCost,
    total_amount,
    payable_amount,
    roomBreakDown,
    totalSelectedRooms,
    rooms,
    prices,
  } = packageDetails;
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [transferID, setTransferID] = useState("");
  const [errors, setErrors] = useState({});
  const [previewFile, setPreviewFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const session_token = login?.session_token || "";
  const navigate = useNavigate();
  if (!packageDetails) return <p>No package data available</p>;
  const checkRoomSelection = () => {
    let totalCapacity = 0;
    Object.keys(selectedRooms).forEach((roomKey) => {
      const room = rooms.find((r) => r.key === roomKey);
      if (room) {
        totalCapacity += room.sleeps * selectedRooms[roomKey];
      }
    });
    return totalCapacity >= travellers; // Ensure room capacity is greater than or equal to number of travellers
  };

  const isRoomSelectionValid = checkRoomSelection();
  const validateTransferID = (value) => {
    if (!value && !selectedFiles) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        transferID: "Please enter a transfer ID or upload a transaction photo.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        transferID: null,
      }));
    }
  };
  const handleFileChange = (e) => {
    const inputSelectedFile = e.target.files[0];
    setSelectedFiles(inputSelectedFile);
    setPreviewFile(URL.createObjectURL(inputSelectedFile));
    validateFile(inputSelectedFile);
  };
  const validateFile = (file) => {
    if (!file && !transferID) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: "Please enter a transfer ID or upload a transaction photo.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    validateTransferID(transferID);
    validateFile(selectedFiles);

    if (errors.transferID || errors.file) {
      setIsLoading(false);
      return;
    }

    try {
      if (transferID) {
        await createBookingRequestbyTransactionNumber(
          session_token,
          bookingResponse.booking_number,
          transferID,
          bookingResponse.total_price
        );
        localStorage.setItem("Transaction-ID", JSON.stringify(transferID));
        navigate("/booking-info", {
          state: {
            transaction_id: transferID,
            packageDetails: packageDetails,
            bookingResponse: bookingResponse,
          },
        });
      } else if (selectedFiles) {
        const formData = new FormData();
        formData.append("session_token", session_token);
        formData.append("booking_number", bookingResponse.booking_number);
        formData.append("transaction_photo", selectedFiles);
        formData.append("transaction_amount", bookingResponse.total_price);
        await createBookingRequestbyTransactionPhoto(formData);
        localStorage.setItem(
          "Transaction-Photo",
          JSON.stringify(selectedFiles)
        );
        navigate("/booking-info", {
          state: {
            transaction_image: selectedFiles,
            packageDetails: packageDetails,
            bookingResponse: bookingResponse,
          },
        });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          transferID:
            "Please enter a transfer ID or upload a transaction photo.",
        }));
      }
    } catch (error) {
      console.error("Error creating booking request:", error);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10 ">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Column */}
          <div className="w-full lg:w-[70%]">
            <PackageHeader data={packageData} />
            <PaymentOption
              transferID={transferID}
              setTransferID={setTransferID}
              validateTransferID={validateTransferID}
              previewFile={previewFile}
              handleFileChange={handleFileChange}
              errors={errors}
            />
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-[30%]">
            <BookingSummary
              data={packageData}
              travellers={travellers}
              selectedRooms={selectedRooms}
              prices={prices}
              rooms={rooms}
              totalSelectedRooms={totalSelectedRooms}
              roomBreakDown={roomBreakDown}
              totalRoomCost={totalRoomCost}
              total_amount={total_amount}
              payable_amount={payable_amount}
              isRoomSelectionValid={isRoomSelectionValid}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentPage;
