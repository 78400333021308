import React from "react";
// import { useModal } from "../../context/ModalContext";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  //   const { isModalOpen, setIsModalOpen } = useModal;
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 md:w-1/2 lg:w-1/3"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute md:right-10 top-10 right-3 md:text-[30px] text-2xl text-white hover:text-gray-500 transition-all duration-150"
          onClick={onClose}
        >
          x
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
