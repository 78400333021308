import React, { useState } from 'react';
import {
  Header,
  Hero,
  PopularPackages,
  AppDownload,
  BrowseByPeople,
  DiscountSection,
  Footer,
  ServicesSection,
} from '../../';
import heroimg from '../../assets/heroImage.png';

const HomePage = () => {
  // State to control the margin
  const [popularPackagesStatus, setPopularPackagesStatus] = useState('loading');

  // Determine the margin class based on the status
  const getMarginClass = () => {
    if (popularPackagesStatus === 'success') return 'lg:mb-[330px]';
    return 'lg:pb-[60px]';
  };

  return (
    <main className="bg-white">
      <div className={`${getMarginClass()}`}>
        <div
          className="w-full h-[650px] lg:h-[950px] bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(0deg, 
            rgba(12, 42, 53, 1) 0%, 
            rgba(24, 77, 97, 0.5) 60%, 
            rgba(12, 42, 53, 1) 100%), 
            url(${heroimg})`,

          }}
        >
          <Header />
          <Hero />
        </div>


        {/* Pass a callback to update the status */}
        <PopularPackages onStatusChange={setPopularPackagesStatus} />
      </div>
      <div
        className=''>
        <ServicesSection />
        <DiscountSection />
        <BrowseByPeople />
        <AppDownload />
        <Footer />
      </div>

    </main>
  );
};

export default HomePage;
