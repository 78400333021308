import dayjs from "dayjs";
import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";
import calenderLogo from "../../../assets/passport/calender.png";
import passportLogo from "../../../assets/passport/passport.png";
const PassportInfo = ({ passports, setPassports, errorMessages }) => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const pakistanOption = countryOptions.find(
    (option) => option.label === "Pakistan"
  );

  const handleInputChange = (index, field, value) => {
    const updatedPassports = [...passports];
    updatedPassports[index] = {
      ...updatedPassports[index],
      [field]: value,
    };
    setPassports(updatedPassports);
  };
  const handleCountryChange = (index, value) => {
    const updatedPassports = [...passports];
    updatedPassports[index].nationality = value.label;
    setPassports(updatedPassports);
  };

  const renderPassportFields = (index) => (
    <div
      key={index}
      className="bg-white border-[1px] border-[#DFE4EA] p-6 rounded-md"
    >
      <h3 className="text-[18px] font-bold mb-4">Traveler {index + 1}</h3>

      {errorMessages[index] && (
        <p className="text-red-500 font-semibold mb-4">
          {errorMessages[index]}
        </p>
      )}
      <div>
        <div className="md:flex space-y-2 md:space-y-0 gap-6 mt-4">
          <div className="w-full space-y-2">
            <label className="text-[14px] font-400">First Name</label>
            <input
              type="text"
              value={passports[index].firstName}
              onChange={(e) =>
                handleInputChange(index, "firstName", e.target.value)
              }
              placeholder="Enter first name"
              className="p-2 text-[16px] outline-none font-500 rounded-md border-[1px] border-[#DFE4EA] w-full"
            />
          </div>
          <div className="w-full space-y-2">
            <label className="text-[14px] font-400">Last Name</label>
            <input
              type="text"
              value={passports[index].lastName}
              onChange={(e) =>
                handleInputChange(index, "lastName", e.target.value)
              }
              placeholder="Enter last name"
              className="p-2 text-[16px] outline-none font-500 rounded-md border-[1px] border-[#DFE4EA] w-full"
            />
          </div>
        </div>
        <div className="md:flex space-y-2 md:space-y-0 gap-6 mt-4">
          <div className="w-full space-y-2">
            <label className="text-[14px] font-400">Date of Birth</label>
            <div className="flex items-center border-[1px] border-[#dfe4ea] rounded-md p-2">
              {/* Icon */}
              <span className="mr-2">
                <img
                  src={calenderLogo}
                  alt="Calendar Icon"
                  className="w-5 h-5"
                />
              </span>
              <input
                type="date"
                value={passports[index].dob || ""}
                onChange={(event) =>
                  handleInputChange(index, "dob", event.target.value)
                }
                max={dayjs().format("YYYY-MM-DD")}
                className="w-full block text-[16px] outline-none text-secondaryBlue placeholder:text-secondaryBlue"
              />
            </div>
          </div>
          <div className="w-full space-y-2">
            <label className="text-[14px] font-400">Nationality</label>
            <Select
              options={countryOptions}
              value={
                countryOptions.find(
                  (option) => option.label === passports[index].nationality
                ) || pakistanOption
              }
              onChange={(value) => handleCountryChange(index, value)}
              className="w-full border-[1px] border-[#DFE4EA] rounded-md text-gray-800 focus:outline-none focus:ring focus:ring-indigo-300"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                control: (base) => ({
                  ...base,
                  height: "36px",
                  fontSize: "0.875rem",
                  minHeight: "36px",
                  borderColor: "#DFE4EA",
                  boxShadow: "none",
                  "&:hover": { borderColor: "#A7A7A7" },
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "0.875rem",
                  backgroundColor: state.isSelected
                    ? "#00936C"
                    : state.isFocused
                    ? "#d1d1d1"
                    : "white",
                  color: state.isSelected ? "white" : "#111827",
                  padding: "8px",
                }),
              }}
            />
          </div>
        </div>
        <div className="md:flex space-y-2 md:space-y-0 gap-6 mt-4">
          <div className="w-full space-y-2">
            <label className="text-[14px] font-400">Passport Number</label>

            <div className="flex items-center border-[1px] border-[#DFE4EA] rounded-md p-2">
              {/* Icon */}
              <span className="mr-2">
                <img
                  src={passportLogo} // Replace with the actual path to your icon
                  alt="Passport Icon"
                  className="w-5 h-5"
                />
              </span>
              {/* Input */}
              <input
                type="text"
                value={passports[index].passportNumber}
                onChange={(e) =>
                  handleInputChange(index, "passportNumber", e.target.value)
                }
                placeholder="Enter Passport Number"
                className="w-full text-[16px] outline-none font-500"
              />
            </div>
          </div>
          <div className="w-full space-y-2">
            <label className="text-[14px] font-400">Passport Expiry</label>
            <div className="flex items-center border-[1px] border-[#dfe4ea] rounded-md p-2">
              {/* Icon */}
              <span className="mr-2">
                <img
                  src={calenderLogo}
                  alt="Calendar Icon"
                  className="w-5 h-5"
                />
              </span>
              <input
                type="date"
                value={passports[index].expiryDate || ""}
                onChange={(event) =>
                  handleInputChange(index, "expiryDate", event.target.value)
                }
                min={dayjs().format("YYYY-MM-DD")}
                className="w-full block text-[16px] outline-none text-secondaryBlue placeholder:text-secondaryBlue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h2 className="text-[24px] font-600 mb-2">Travelers Info</h2>
      <form className="space-y-6">
        {passports.map((_, index) => renderPassportFields(index))}
      </form>
    </div>
  );
};

export default PassportInfo;
