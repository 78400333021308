// src/pages/HomePage/components/Hero.js
import React from 'react';
import { SearchBar } from '../../../';

const Hero = () => {
  return (
    <div className="w-all-sections-width mx-auto flex flex-col items-start text-left mt-24 ">
      <h2 className="ml-3 md:ml-0 h1 font-bold mb-8">
        <p className=" text-white">Find the best</p>
        <p className="text-brandGold">
          Umrah Package
  <span className="text-white inline-block w-3 h-3 bg-white rounded-full ml-1"></span>
        </p>
      </h2>
      <SearchBar />
    </div>
  );
};

export default Hero;
