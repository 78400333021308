import React from "react";
import hajjGuide from "../../../assets/homepage/browseSection/single.png";
import umrahGuide from "../../../assets/homepage/browseSection/couple.png";
import preparationTips from "../../../assets/homepage/browseSection/3people.png";
import travelEssentials from "../../../assets/homepage/browseSection/4ormore.png";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay } from "swiper/modules";

const BrowseByGuidesArabic = () => {
  const data = [
    {
      imagee: hajjGuide,
      text: "دليل الحج",
      description: "إرشادات خطوة بخطوة لرحلة حج مرضية روحياً.",
      link: "/Hajj-Guidance",
    },
    {
      imagee: umrahGuide,
      text: "دليل العمرة",
      description: "كل ما تحتاج إلى معرفته لأداء العمرة بكل سهولة.",
      link: "/Umrah-Guidance",
    },
    {
      imagee: preparationTips,
      text: "نصائح التحضير",
      description: "نصائح أساسية للاستعداد جسديًا وعقليًا وروحيًا.",
      link: "/browse-tips",
    },
    {
      imagee: travelEssentials,
      text: "أساسيات السفر",
      description: "قائمة مرجعية شاملة لرحلة خالية من المتاعب.",
      link: "/travel-essential",
    },
  ];

  return (
    <div className="w-all-sections-width mx-auto text-[30px] my-s10 md:my-m12 lg:my-l14 font-[700] text-secondaryBlue">
      <p className="h2 text-start md:text-right">تصفح أدلة الحج والعمرة</p>
      <p className="text-primaryGray text-[14px] text-start md:text-right font-thin mt-2 sm:mt-0">
        ابحث عن الأدلة والموارد الأساسية لرحلة الحج والعمرة. سواء كنت تستعد
        للرحلة أو تبحث عن نصائح للسفر، استكشف كل ما تحتاجه لتجعل رحلتك الروحية
        سلسة.
      </p>
      {/* Only XL screen */}
      <div className="xl:flex justify-between mt-[42px] hidden gap-x-6">
        {data.map((item, index) => {
          return (
            <div
              key={index} // Always include a `key` when mapping in React
              className="flex flex-col sm:items-center space-y-2"
            >
              <a
                href={item.link}
                className="w-full h-full overflow-hidden rounded-md"
              >
                <img
                  src={item.imagee}
                  alt={item.text}
                  className="w-full h-full object-cover"
                />
              </a>
              <a href={item.link} className="text-center">
                <p className="text-[24px] font-medium text-black">
                  {item.text}
                </p>
                <p className="text-[12px] font-light text-primaryGray">
                  {item.description}
                </p>
              </a>
            </div>
          );
        })}
      </div>

      {/* Mobile to Large screen (except XL) */}
      <div className="flex justify-between mt-[20px] md:mt-[32px] lg:mt-[42px] xl:hidden">
        <Swiper
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000, // Delay between slides in milliseconds
            disableOnInteraction: false, // Keeps autoplay running even after user interaction
          }}
          modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            720: {
              slidesPerView: 3, // Show 3 slides for screens 720px and above
            },
            1024: {
              slidesPerView: 4, // Show 4 slides for screens 1024px and above
            },
          }}
          className="mySwiper"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="flex flex-col sm:items-center space-y-3 md:text-left lg:text-center">
                  <a href={item.link}>
                    <img src={item.imagee} alt={item.text} />
                  </a>
                  <div>
                    <p className="text-[16px] font-medium text-black">
                      {item.text}
                    </p>
                    <p className="text-[14px] font-light text-primaryGray">
                      {item.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default BrowseByGuidesArabic;
