import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiErrorAlt } from "react-icons/bi";
import { sendOtp } from "../../api/AuthApi";
import AuthContext from "../../context/AuthContext";
import { ButtonsLoader } from "../..";
const Otp = ({ onUserDoesNotExist, closeModal }) => {
  const userFromBooking = true;
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const inputRefs = useRef(otp.map(() => React.createRef()));
  const navigate = useNavigate();
  const { setLogin } = useContext(AuthContext);
  const handleOtpChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value.substr(value.length - 1, 1);
      setOtp(newOtp);

      if (value.length > 0 && index < otp.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }

      if (value === "" && index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
      setErrors(""); // Clear errors when user starts entering data
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    const newOtp = pasteData.split("").map((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].current.value = char;
        if (index < otp.length - 1) {
          inputRefs.current[index + 1].current.focus();
        }
      }
      return char;
    });
    setOtp(newOtp);
  };

  const user_phone_number =
    JSON.parse(localStorage.getItem("User-Phone-Number")) || "";
  const userExist = true;
  const handleOtpSubmit = async () => {
    setIsLoading(true);
    setErrors(""); // Clear errors
    const enteredOtp = otp.join("");
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // For now, use static OTP verification
    if (enteredOtp === "123456") {
      if (userExist !== "User with this phone number does not exist.") {
        if (userFromBooking) {
          const response =
            JSON.parse(localStorage.getItem("Login-User-Profile")) || "";
          setLogin(response);
          closeModal();
        } else {
          navigate("/");
        }
      } else {
        onUserDoesNotExist();
      }
    } else {
      setErrors("Invalid OTP. Please try again.");
    }
    setIsLoading(false);
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    setErrors(""); // Clear errors
    try {
      await sendOtp(user_phone_number);
    } catch (error) {
      console.error("Error resending OTP:", error);
      setErrors(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <main className="">
        <div className=" bg-white p-12 rounded-lg text-center">
          <h2 className="text-lg font-semibold text-[#4B465C]">
            Verify your phone number to proceed
          </h2>
          <p className="mb-6 text-sm font-normal text-[#4B465C] opacity-80">
            We sent a verification code to{" "}
            <span className="font-bold text-[#4B465C] opacity-100">
              {user_phone_number}
            </span>
            <br />
            Enter the code sent to your phone number in the field below.
          </p>

          <div className="flex justify-between mb-6" onPaste={handlePaste}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                id={`otp-input-${index}`}
                ref={inputRefs.current[index]}
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                placeholder="-"
                onFocus={(e) => e.target.select()}
                className="w-12 p-3 border rounded-md text-center text-sm"
              />
            ))}
          </div>
          {errors && (
            <div className="text-red-500 flex my-3 items-center gap-1">
              <BiErrorAlt />
              <span className="text-red-500 text-xs">{errors}</span>
            </div>
          )}

          <button
            onClick={handleOtpSubmit}
            className={`text-sm w-full p-3 mb-3 bg-brandGold text-white rounded-md flex items-center justify-center ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <ButtonsLoader message="" type="loading" />
            ) : (
              <p>Continue</p>
            )}
          </button>

          <p className="text-sm text-[#4B465C]">
            Did not receive email?{" "}
            <button
              className="text-brandGold underline font-semibold"
              onClick={handleResendOtp}
            >
              Resend
            </button>
          </p>
        </div>
      </main>
    </div>
  );
};

export default Otp;
