import React, { useEffect, useState } from "react";
import { Footer, Header, SortingDiv } from "../../";
import banner from "../../assets/Detail-Page/banner.png";
import { fetchBookingsByUser } from "../../api/apiService";
import { Loader } from "lucide-react";
import Pagination from "../../components/Pagination/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
const PackageCard = React.forwardRef(({ pkg }, ref) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  const navigate = useNavigate();
  // const { selectedCurrency, exchangeRates } = useContext(CurrencyContext);
  const { state: locationState } = useLocation();
  const { huz_token, partner_session_token } = locationState || {};
  const handleViewDetails = () => {
    const bookingDetails = {
      totalAdults: pkg.adults,
      totalCost: pkg.total_price,
      startDate: pkg.start_date,
      endDate: pkg.end_date,
      totalLengthOfStay:
        parseInt(pkg.mecca_nights) + parseInt(pkg.madinah_nights),
      bookingNumber: pkg.booking_number,
      bookingResponse: pkg,
    };

    if (pkg.booking_status === "Passport_Validation") {
      let targetUrl = "/error";
      if (pkg.payment_type === "Bank") {
        targetUrl = "/payment-page";
      } else if (pkg.payment_type === "Voucher") {
        targetUrl = "/payment-page";
      }

      navigate(targetUrl, {
        state: bookingDetails,
      });
    } else if (pkg.booking_status === "Initialize") {
      let targetUrl = "/traveller-info-page";

      navigate(targetUrl, {
        state: {
          huz_token,
          partner_session_token,
          totalAdults: pkg.adults,
          totalCost: pkg.total_price,
          bookingResponse: pkg, // Pass the full booking response
          booking_number: pkg.booking_number, // Pass booking number
          user_session_token: pkg.user_session_token, // Pass session token
          payment_type: pkg.payment_type, // Adding the payment method state
          returnDate: pkg.end_date, // Pass the return date (end date of the package)
        },
      });
    } else {
      navigate(`/booking-info/${pkg.booking_number}`, {
        state: {
          ...bookingDetails,
          sessionToken: pkg.user_session_token,
        },
      });
    }
  };
  const statusStyle =
    pkg.booking_status === "Objection" ? { color: "#CB5353" } : {};
  const paidAmount = pkg.payment_detail?.[0]?.transaction_amount;
  const remainingAmount = pkg.total_price - paidAmount;
  const totalDays = Math.floor(
    (new Date(pkg.end_date) - new Date(pkg.start_date)) / (1000 * 3600 * 24)
  );
  return (
    <div className="text-secondaryBlue">
      <div className="mt-6 p-6 bg-white md:shadow-custom-box rounded-b-none rounded-lg border-t-[1px] border-x-[1px] border-x-[#DCDCDC] border-t-[#DCDCDC] overflow-hidden md:my-10 relative">
        <div className="hidden md:flex">
          <div className="xl:w-[30%] md:w-[35%] m-3 mt-0 border-r-[5px]">
            <h3 className="xl:text-xl md:text-lg opacity-90 font-semibold">
              {pkg.package_name}
            </h3>
            <p className="xl:text-[15px] md:text-[13px] font-bold opacity-80">
              <NumericFormat
                value={pkg.total_price}
                displayType={"text"}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
            <div className="xl:mt-8 lg:mt-6 md:mt-3 space-y-1 text-[14px] opacity-80">
              <p>
                {pkg.is_visa_included === "True" && "Visa"}
                {pkg.is_visa_included === "True" && "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_insurance_included === "True" && "Insurance"}
                {pkg.is_insurance_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_airport_reception_included === "True" &&
                  "Airport Reception"}{" "}
                {pkg.is_airport_reception_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_breakfast_included === "True" && "Breakfast"}
                {pkg.is_breakfast_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_lunch_included === "True" && "Lunch"}
                {pkg.is_lunch_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
                {pkg.is_dinner_included === "True" && "Dinner"}
                {pkg.is_dinner_included === "True" &&
                  "\u00A0\u00A0-\u00A0\u00A0"}
              </p>
            </div>
          </div>

          <div className="w-[70%] p-3 pt-0 lg:pl-6 xl:pl-10">
            <div className="flex md:justify-between lg:gap-x-6 xl:gap-x-10 items-center">
              <div className="grid grid-cols-3 space-y-2">
                <div>
                  <span className="block opacity-50 text-[13px] font-medium">
                    Total Cost
                  </span>
                  <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                    {" "}
                    <NumericFormat
                      value={pkg.total_price}
                      displayType={"text"}
                      thousandSeparator
                      //   prefix={`${selectedCurrency} `}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />{" "}
                  </p>
                </div>
                <div>
                  <span className="block opacity-50 text-[13px] font-medium">
                    Paid Amount
                  </span>
                  <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                    {" "}
                    <NumericFormat
                      value={paidAmount}
                      displayType={"text"}
                      thousandSeparator
                      //   prefix={`${selectedCurrency} `}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />{" "}
                  </p>
                </div>
                <div>
                  <span className="block opacity-50 text-[13px] font-medium">
                    Remaining Amount
                  </span>
                  <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                    {" "}
                    <NumericFormat
                      value={remainingAmount}
                      displayType={"text"}
                      thousandSeparator
                      //   prefix={`${selectedCurrency} `}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />{" "}
                  </p>
                </div>
                <div>
                  <span className="block text-[13px] opacity-50 font-medium">
                    # of Travellers
                  </span>
                  <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                    {pkg.adults}
                  </p>
                </div>
                <div>
                  <span className="block text-[13px] opacity-50 font-medium">
                    Package Duration
                  </span>
                  <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                    {totalDays}
                  </p>
                </div>
                <div className="block md:hidden lg:block">
                  <span className="block text-[13px] opacity-50 font-medium">
                    Start date & End date
                  </span>
                  <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                    {new Date(pkg.start_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    to{" "}
                    {new Date(pkg.end_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </div>
              <div
                className="block bg-yellow-50 border-[1px] border-brandGold rounded-[3px] px-3 text-[13px] font-semibold text-brandGold "
                style={statusStyle}
              >
                {pkg.booking_status}
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="hidden md:block lg:hidden">
                <span className="block text-[13px] opacity-50 font-medium">
                  Start date & End date
                </span>
                <p className="opacity-80 font-semibold md:text-[13px] xl:text-[15px]">
                  {new Date(pkg.start_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  to{" "}
                  {new Date(pkg.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="block md:hidden ">
          <div className="">
            <div className="">
              <div className="flex justify-between items-center">
                <h3 className="text-lg opacity-90 font-semibold text-[#4B465C]">
                  {pkg.package_name}
                </h3>
                <div
                  className="block bg-green-50 border-[1px] border-[#00936c] rounded-[3px] px-3 text-[13px] font-semibold text-[#00936C]"
                  style={statusStyle}
                >
                  {pkg.booking_status}
                </div>
              </div>
              <p className="text-[13px] font-bold opacity-80 text-[#4b465c]">
                <NumericFormat
                  value={pkg.total_price}
                  displayType={"text"}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <div className="">
              <div className="flex justify-between items-center my-4 ">
                <div className="block md:hidden lg:block">
                  <span className="block text-[13px] opacity-50 font-medium">
                    Start date & End date
                  </span>
                  <p className="opacity-80 font-semibold text-[13px] ">
                    {new Date(pkg.start_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    to{" "}
                    {new Date(pkg.end_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </div>
                <div>
                  <span className="block text-[13px] opacity-50 font-medium">
                    Adults & Child
                  </span>
                  <p className="opacity-80 font-semibold text-[13px] ">
                    {pkg.adults} - {pkg.child}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const AllBookings = () => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]); // Filtered packages
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [sortOrder, setSortOrder] = useState({
    value: "Recommended",
    label: "Recommended",
  });
  useEffect(() => {
    let sortedPackages = [...packages];
    if (sortOrder.value === "low-to-high") {
      sortedPackages.sort((a, b) => a.total_price - b.total_price);
    } else if (sortOrder.value === "high-to-low") {
      sortedPackages.sort((a, b) => b.total_price - a.total_price);
    }
    setFilteredPackages(sortedPackages);
  }, [sortOrder, packages]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = JSON.parse(
          localStorage.getItem("Login-User-Profile")
        );
        if (userProfile && userProfile.session_token) {
          const sessionToken = userProfile.session_token;
          const data = await fetchBookingsByUser(sessionToken);
          setPackages(data);
        } else {
          throw new Error("No session token found");
        }
      } catch (err) {
        if (err.message === "Booking detail not found") {
          setPackages([]);
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (packages.length === 0) {
    return (
      <div>
        <Header />
        {/* <NoBookingsCard /> */}
        There is no booking here
        <hr className="w-full" />
        <Footer />
      </div>
    );
  }

  const totalPages = Math.ceil(packages.length / itemsPerPage);

  const currentPackages = filteredPackages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <Header />
      <div className="w-all-sections-width mx-auto mb-10 ">
        <img src={banner} alt="banner-image" className="w-full my-10" />
        <div>
          <p className="text-[40px] font-700 mb-2">My Bookings</p>
          <SortingDiv sortOrder={sortOrder} setSortOrder={setSortOrder} />

          {currentPackages.map((pkg) => (
            <PackageCard key={pkg.booking_id} pkg={pkg} />
          ))}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllBookings;
