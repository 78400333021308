import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { Listbox } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useModal } from "../../../context/ModalContext";

const cities = [
  "اسلام اباد",
  "كراتشي",
  "لاهور",
  "ملتان",
  "فيصل أباد",
  "بيشاور",
  "كويتا",
  "سيالكوت",
];

const SearchBarArabic = () => {
  const [departureLocation, setDepartureLocation] = useState("");
  const [departureDate, setDepartureDate] = useState(null);
  const navigate = useNavigate();
  const { isModalOpen } = useModal();
  const handleSearch = () => {
    const formattedDate = departureDate
      ? departureDate.toISOString().split("T")[0]
      : "";

    const queryParams = new URLSearchParams({
      departureLocation: departureLocation || "",
      departureDate: formattedDate || "",
    });

    navigate(`/listing-page?${queryParams.toString()}`);
  };

  // Validation: Check if all required fields are filled
  const isSearchEnabled = () => {
    return departureLocation.trim() !== "" && departureDate !== null;
  };

  return (
    <div
      className={`flex flex-col lg:flex-row items-center p-4 rounded-t-semiRound rounded-br-semiRound lg:py-2 lg:px-4 gap-3 bg-[rgba(24,77,97,0.39)] backdrop-blur-[35px] lg:rounded-fullRound mx-auto w-full lg:justify-between  ${
        isModalOpen ? "" : "z-50"
      } `}
    >
      {/* Departure Location Dropdown */}
      <div className="flex items-center w-full h-[52px] gap-3 mt-2 lg:mt-0">
        <FaMapMarkerAlt className="text-lg text-brandGold opacity-55" />
        <Listbox
          as="div"
          value={departureLocation}
          onChange={setDepartureLocation}
        >
          <Listbox.Button className="w-full text-base md:text-lg font-light text-white bg-transparent placeholder-white outline-none">
            {departureLocation || "البحث عن مواقع المغادرة"}
          </Listbox.Button>
          <Listbox.Options className="absolute mt-1 w-auto lg:w-[20%] bg-white text-darkBlue rounded-md shadow-lg ring-1 ring-black ring-opacity-5 max-h-40 z-50 overflow-auto focus:outline-none">
            {cities.map((city) => (
              <Listbox.Option key={city} value={city}>
                {({ active, selected }) => (
                  <div
                    className={`${
                      active ? "bg-brandGold text-black" : "text-gray-900"
                    } cursor-pointer select-none relative py-2 px-4`}
                  >
                    {selected && (
                      <span className="absolute inset-y-0 z-40 right-0 flex items-center pr-3 text-brandGold">
                        &#10003;
                      </span>
                    )}
                    {city}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>

      {/* Departure Date */}
      <div className="relative flex items-center w-full h-[52px] gap-3 mt-2 lg:mt-0">
        <FaCalendarAlt className="text-lg text-brandGold opacity-55" />
        <DatePicker
          selected={departureDate}
          onChange={(date) => setDepartureDate(date)}
          minDate={new Date()}
          placeholderText="تاريخ المغادرة"
          className="w-full text-base font-light md:text-lg text-white bg-transparent outline-none placeholder-white"
          calendarClassName="custom-datepicker"
          popperClassName="custom-datepicker-popper"
          popperPlacement="bottom-start"
        />
      </div>

      {/* Search Button */}
      <button
        onClick={handleSearch}
        disabled={!isSearchEnabled()}
        className={`flex items-center justify-center w-full lg:w-auto px-6 py-3 rounded-full mt-2 lg:mt-0 ${
          isSearchEnabled()
            ? "bg-brandGold text-black hover:bg-opacity-90"
            : "bg-gray-300 text-gray-500 cursor-not-allowed"
        }`}
      >
        يبحث
      </button>
    </div>
  );
};

export default SearchBarArabic;
