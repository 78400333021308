import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Page:
export { default as HomePage } from "./pages/HomePage/HomePage";

//Its Components:
export { default as Header } from "./components/Header/Header";
export { default as Hero } from "./pages/HomePage/components/Hero";
export { default as SearchBar } from "./components/SearchBar/SearchBar";
export { default as Footer } from "./components/Footer/Footer";
export { default as AppDownload } from "./components/AppDownload/AppDownload";
export { default as PopularPackages } from "./pages/HomePage/components/PopularPackages"; // PopularPackages section
export { default as ServicesSection } from "./components/ServicesSection/ServicesSection";
export { default as DiscountSection } from "./pages/HomePage/components/DiscountSection";
export { default as BrowseByPeople } from "./pages/HomePage/components/BrowseByPeople";
export { default as MessageDisplay } from "./components/MessageDisplay/MessageDisplay";
export { default as PackageCardHome } from "./components/PackageCardHome/PackageCardHome";

//Page:
export { default as ListingPage } from "./pages/ListingPage/ListingPage";

//components:
export { default as SortingDiv } from "./pages/ListingPage/components/SortingDiv";
export { default as FiltersComponent } from "./pages/ListingPage/components/Filters";
export { default as PackageCard } from "./pages/ListingPage/components/PackageCard";
export { default as PackageListing } from "./pages/ListingPage/components/PackageListing";

//Page:
export { default as PackageDetailPage } from "./pages/PackageDetailPage/PackageDetailPage";

//components
export { default as PackageHeader } from "./pages/PackageDetailPage/components/LeftColumn/PackageHeader";
export { default as Itinerary } from "./pages/PackageDetailPage/components/LeftColumn/Itinerary";
export { default as RoomOptions } from "./pages/PackageDetailPage/components/LeftColumn/RoomOptions";
export { default as BookingSummary } from "./pages/PackageDetailPage/components/RightColumn/BookingSummary";
export { default as TransportDetails } from "./pages/PackageDetailPage/components/LeftColumn/TransportDetail";
export { default as AirlineDetails } from "./pages/PackageDetailPage/components/LeftColumn/AirlineDetails";
export { default as PackageItinerary } from "./pages/PackageDetailPage/components/LeftColumn/PackageItinerary";
export { default as Verification } from "./pages/PackageDetailPage/components/LeftColumn/Verification";

//Page:
export { default as Login } from "./pages/Login/Login";
export { default as SignUp } from "./pages/Signup/Signup";

//Page:
export { default as TravellerInfoPage } from "./pages/TravellerInfoPage/TravellerInfoPage";

//components
export { default as PassportInfo } from "./pages/TravellerInfoPage/components/PassportInfo";

//Page
export { default as Otp } from "./pages/Otp/Otp";

//Page
export { default as PaymentPage } from "./pages/PaymentPage/PaymentPage";

//component
export { default as PaymentInfo } from "./pages/PaymentPage/components/PaymentInfo";
export { default as BillingAddress } from "./pages/PaymentPage/components/BillingAddress";
export { default as BankingInfo } from "./pages/PaymentPage/components/BankingInfo";

//Page
export { default as BookingInfoPage } from "./pages/Bookings/BookingInfoPage/BookingInfoPage";

//component
export { default as BookingInfo } from "./pages/Bookings/BookingInfoPage/components/BookingInfo";
export { default as BookingDetail } from "./pages/Bookings/BookingInfoPage/components/BookingDetail";

//Modal
export { default as Modal } from "./components/Modal/Modal";

//component
export { default as UnderDevelopment } from "./components/UnderDevelopment/UnderDevelopment";

//Page
export { default as About } from "./pages/About/About";
export { default as Contactus } from "./pages/Contactus/Contactus";
export { default as HajjGuidance } from "./pages/StaticPages/Guidance/HajjGuidance/HajjGuidance";
export { default as UmrahGuidance } from "./pages/StaticPages/Guidance/UmrahGuidance/UmrahGuidance";
export { default as BrowsingTipsPage } from "./pages/StaticPages/BrowsingTipsPage/BrowsingTipsPage";
export { default as TravelEssential } from "./pages/StaticPages/TravelEssential/TravelEssential";

//component
export { default as ButtonsLoader } from "./components/ButtonsLoader/ButtonsLoader";

//Arabic Homepage Files
export { default as HomePageArabic } from "./pages/HomePageArabic/HomePageArabic";
export { default as BrowseByPeopleArabic } from "./pages/HomePageArabic/components/BrowseByPeopleArabic";
export { default as DiscountSectionArabic } from "./pages/HomePageArabic/components/DiscountSectionArabic";
export { default as HeroArabic } from "./pages/HomePageArabic/components/HeroArabic";
export { default as PopularPackagesArabic } from "./pages/HomePageArabic/components/PopularPackagesArabic";
export { default as ServicesSectionArabic } from "./pages/HomePageArabic/components/ServicesSectionArabic";
export { default as AppDownloadArabic } from "./pages/HomePageArabic/components/AppDownloadArabic";
export { default as FooterArabic } from "./pages/HomePageArabic/components/FooterArabic";
export { default as HeaderArabic } from "./pages/HomePageArabic/components/HeaderArabic";
export { default as SearchBarArabic } from "./pages/HomePageArabic/components/SearchBarArabic";

//pages
export { default as VisaServices } from "./pages/VisaServices/VisaServices";
export { default as ConfirmVisa } from "./pages/VisaServices/ConfirmVisa/ConfirmVisa";

//component
export { default as PaymentOption } from "./pages/PaymentPage/components/PaymentOption";
export { default as JazzInfo } from "./pages/PaymentPage/components/JazzInfo";
export { default as VoucherInfo } from "./pages/PaymentPage/components/VoucherInfo";

//pages
export { default as AllBookings } from "./pages/Bookings/AllBookings";
