import React from "react";
import bankLogo from "../../../assets/Payment/bank.svg";
const BankingInfo = ({
  transferID,
  validateTransferID,
  handleTransferInputChange,
  errors
}) => {
  return (
    <div className="text-secondaryBlue">
      <p className="text-[24px] font-600 my-2">Bank Transfer </p>
      <div className="space-y-5 p-6 border-[1px] border-[#dfe4ea] bg-white rounded-md">
        <p>Here are the bank account details where you can make the payment:</p>
        <div className="flex gap-4">
          <img src={bankLogo} alt="Bank" className="h-12" />
          <div className="text-xs">
            <p>
              <span className="font-bold">Account Title:</span> HUZ Solutions
              (Pvt) Limited.
            </p>
            <p>
              <span className="font-bold">Account #:</span> 092349328Z234
            </p>
            <p>
              <span className="font-bold">IBFA:</span> 03409349034903439490
            </p>
          </div>
        </div>
        <div className="space-y-1">
          <label>Enter your transfer ID:</label>
          <input
            type="text"
            placeholder="Enter Transfer ID here"
            value={transferID}
            onChange={handleTransferInputChange}
            onBlur={() => validateTransferID(transferID)}
            className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#dfe4ea]"
          />
        </div>
        {errors.file && <p className="text-red-500 text-xs">{errors.file}</p>}
      </div>
    </div>
  );
};

export default BankingInfo;
