import React from "react";
import Select from "react-select";
import { AiOutlineBars } from "react-icons/ai";

const SortDropdownButton = ({ sortOrder, setSortOrder }) => {
  const sortOptions = [
    { value: "Recommended", label: "Recommended" },
    { value: "Top Reviewed", label: "Top Reviewed" },
    { value: "low-to-high", label: "Price: Low to High" },
    { value: "high-to-low", label: "Price: High to Low" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "none", // Removes the border
      padding: "0", // Removes any default padding
      cursor: "pointer", // Ensures the cursor appears as a pointer
      minHeight: "auto", // Ensures the minimum height is auto, removing any extra space
      width: "169px", // Sets a specific width
      fontSize: "16px", // Corrected from 'font-size' to 'fontSize'
      lineHeight: "24px", // Corrected from 'line-height' to 'lineHeight'
    }),

    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? "#F7F7F7" : "white",
      color: "#637381",
      cursor: "pointer",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#637381",
    }),
  };

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between bg-custom-gradient border-[1px] border-custom-gray rounded-[8px] px-5 py-4">
      {/* Sort Dropdown with Label */}
      <div className="flex items-center gap-0 sm:w-auto bg-white border-[1px] border-custom-gray rounded-[5px] pl-4 py-1">
        <span className="text-black text-base">Sort by:</span>
        <div className="w-auto sm:w-48 text-sm">
          <Select
            options={sortOptions}
            value={sortOrder}
            onChange={setSortOrder}
            styles={customStyles}
            isSearchable={false}
          />
        </div>
      </div>

      {/* View Icons */}
      <button className="p-3 border border-lightGray rounded-lg text-white bg-brandGold hover:shadow-md">
        <AiOutlineBars size={20} />
      </button>
    </div>
  );
};

export default SortDropdownButton;
