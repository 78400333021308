import React from "react";
import { Bus, Circle, MapPin } from "lucide-react";

const TransportDetails = ({ data }) => {
  const {
    transport_name,
    transport_type,
    routes: detailPackageRoutes,
  } = data && data.transport_detail[0];
  const detailRoutes = {
    "Jeddah to Mecca": { city: "Jeddah JED", time: "1h 15m", active: true },
    "Mecca to Madinah": { city: "Mecca MEC", time: "2h 20m" },
    "Madinah to Mecca": { city: "Madinah MED", time: "2h 20m" },
    "Mecca to Jeddah": { city: "Jeddah JED", time: "1h 15m" },
  };
  const routes = detailPackageRoutes
    ? detailPackageRoutes
        .split(",")
        .map((route) => detailRoutes[route.trim()])
        .filter(Boolean)
    : [];
  return (
    <div className="w-full mb-4 lg:mb-0">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-xl font-semibold">
          {transport_type} {transport_name}
        </h2>
        <Bus className="w-5 h-5" />
      </div>

      <div className="bg-white rounded-xl p-4 border border-[#DFE4EA]">
        <div className="relative">
          {routes.map((route, index) => (
            <div key={index} className="flex items-start gap-3 relative">
              <div className="flex flex-col items-center">
                {index === 0 ? (
                  <Circle className="w-4 h-4 text-[#D1A554] fill-[#D1A554]" />
                ) : (
                  <MapPin className="w-4 h-4 text-[#C2C2C2]" />
                )}
                {index !== routes.length - 1 && (
                  <div className="w-[2px] h-8 bg-[#C2C2C2] my-1">
                    <div className="flex flex-col gap-[2px] mt-2">
                      {[...Array(4)].map((_, i) => (
                        <div key={i} className="w-[2px] h-[2px] bg-[#C2C2C2]" />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between w-full pb-4">
                <span className="text-sm text-[#626971] font-medium">
                  {route.city}
                </span>
                <div className="text-right">
                  <span className="text-xs font-medium text-[#111928]">
                    Direct
                  </span>
                  {/* <p className="text-xs text-[#637381]">{route.time}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransportDetails;
