import axios from "axios";
// Ensure the token is correctly formatted without spaces
const authHeader = `${process.env.REACT_APP_AUTH_TOKEN}`;
const baseURL = process.env.REACT_APP_API_BASE_URL;

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: authHeader,
  },
};
//Login
export const checkUserExistence = async (phone_number) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/is_user_exist/`,
      { phone_number },
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return error.response;
    }
    throw error;
  }
};

export const sendOtp = async (phoneNumber) => {
  const data = JSON.stringify({ phone_number: phoneNumber });
  try {
    const response = await axios.post(
      `${baseURL}/common/send_otp_sms/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const verifyOtp = async (phoneNumber, otp) => {
  const data = JSON.stringify({ phone_number: phoneNumber, otp: otp });
  try {
    const response = await axios.post(
      `${baseURL}/common/verify_otp/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const Signup = async ({ phone_number, name, email, user_type }) => {
  try {
    const response = await axios.post(
      `${baseURL}/common/manage_user_account/`,
      {
        phone_number,
        name,
        email,
        user_type,
      },
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { message: "Something went wrong" };
    }
    throw error;
  }
};
