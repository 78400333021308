import React from "react";
import { BillingAddress } from "../../../";
import lockLogo from "../../../assets/Payment/lock.png";
import ecllipseLogo from "../../../assets/Payment/ecllipse.png";
import cardsImage from "../../../assets/Payment/cards.png";
const PaymentInfo = ({
  transferID,
  setTransferID,
  validateTransferID,
  errors,
}) => {
  const handleTransferInputChange = (event) => {
    setTransferID(event.target.value);
    validateTransferID(event.target.value);
  };
  return (
    <div className="text-secondaryBlue">
      <p className="text-[24px] font-600 my-2">
        Debit / Credit Card Information{" "}
      </p>
      <div className="space-y-5 p-6 border-[1px] border-[#DFE4EA] bg-white rounded-md">
        <div className="md:flex w-full justify-between">
          <div className="xl:w-full justify-end">
            <div className=" space-y-5">
              <img src={cardsImage} alt="card logo" />
              <div>
                <div className="space-y-2 text-sm">
                  <div className="md:flex gap-4">
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Enter Card Number:
                      </label>
                      <input
                        type="text"
                        value={transferID}
                        onChange={handleTransferInputChange}
                        onBlur={() => validateTransferID(transferID)}
                        placeholder="Enter Card Number"
                        className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#DFE4EA]"
                      />
                    </div>
                    <div className="space-y-2 w-full">
                      <label className="text-[14px] font-semibold">
                        Expiration Date:
                      </label>
                      <input
                        type="text"
                        placeholder="MM/YY"
                        className="w-full p-2.5 text-sm rounded-md border-[1px] border-[#DFE4EA]"
                      />
                    </div>
                  </div>
                  <div className="space-y-2 flex flex-col mt-2 md:mt-4">
                    <label className="text-[14px] font-semibold">CVV:</label>
                    <input
                      type="text"
                      placeholder="***"
                      className="w-full md:w-1/2 p-2.5 text-sm rounded-md border-[1px] border-[#DFE4EA]"
                    />
                  </div>
                  {errors.file && <p className="text-red-500 text-xs">{errors.file}</p>}
                  <div className="flex gap-2 items-center">
                    <div className="relative ">
                      <img
                        src={ecllipseLogo}
                        alt="ecllipse"
                        className="object-cover"
                      />
                      <img
                        src={lockLogo}
                        alt="lock"
                        className="absolute top-1/2 left-3.5 transform -translate-x-1/2 -translate-y-1/2"
                      />
                    </div>

                    <p className="text-[14px] font-400 text-[#A8A8AA]">
                      We protect your payment information using encryption to
                      provide bank-level security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BillingAddress />
    </div>
  );
};

export default PaymentInfo;
