// src/pages/HomePage/components/PackageCarousel.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {PackageCardHome} from '../../../';
import { Pagination, Autoplay } from 'swiper/modules';

const PackageCarousel = ({ packages }) => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      slidesPerGroup={1}
      pagination={false }
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      loop={false}
      breakpoints={{
        640: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 0 },
        768: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 20 },
        1024: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 24 },
      }}
    >
      {packages.map((pkg) => (
        <SwiperSlide key={pkg.huz_token}>
          <PackageCardHome packageData={pkg} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PackageCarousel;
