import React, { useEffect } from "react";
import { BathIcon, MinusIcon, PlusIcon, UsersIcon } from "lucide-react";
const RoomOptions = ({
  travellers,
  selectedRooms,
  setSelectedRooms,
  rooms,
  totalSelectedRooms,
}) => {
  useEffect(() => {
    setSelectedRooms({
      double: 0,
      triple: 0,
      quad: 0,
      sharing: `${travellers}`,
    });
  }, [travellers]);

  const handleDecrement = (roomKey) => {
    setSelectedRooms((prev) => ({
      ...prev,
      [roomKey]: Math.max(0, prev[roomKey] - 1),
    }));
  };

  const handleIncrement = (roomKey) => {
    setSelectedRooms((prev) => ({
      ...prev,
      [roomKey]: prev[roomKey] + 1,
    }));
  };
  return (
    <div className="mb-10">
      <h1 className="text-2xl font-bold mb-4">Choose room</h1>
      <div className="space-y-4">
        {rooms.map((room) => (
          <div
            key={room.key}
            className="bg-white rounded-xl border border-[#DFE4EA] overflow-hidden p-4"
          >
            <div className="grid gap-4 lg:flex lg:justify-between">
              {/* Column 1: Image */}
              <div className="w-full lg:w-auto xl:w-[30%]">
                <img
                  src={room.image}
                  alt={room.type}
                  className="w-full lg:w-[135px] h-[135px] object-cover rounded-lg shadow-md"
                />
              </div>

              {/* Column 2: Room Details */}
              <div className="flex flex-col md:justify-between lg:justify-start gap-4 w-full">
                <div>
                  <h2 className="text-lg font-bold text-[#111928] lg:mb-1 mb-2">
                    {room.type}
                  </h2>
                  <p className="text-sm text-[#637381] mb-2">
                    {room.description}
                  </p>
                  <div className="flex items-center gap-4 mb-2">
                    <div className="flex items-center gap-2 text-[#D1A554]">
                      <UsersIcon className="w-5 h-5" />
                      <span className="text-xs font-medium">
                        Sleeps {room.sleeps}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-[#D1A554]">
                      <BathIcon className="w-5 h-5" />
                      <span className="text-xs font-medium">
                        {room.bathroom}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  {room.tags.map((tag, index) => (
                    <span
                      key={index}
                      className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        tag.includes("Best")
                          ? "border border-[#22AD5C] text-[#22AD5C]"
                          : "border border-[#F59E0B] text-[#D97706]"
                      }`}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>

              {/* Column 3: Pricing and Actions */}
              <div className="flex lg:flex-col justify-between lg:justify-start items-end lg:items-start gap-5 w-full lg:w-[40%]">
                <div className="text-left">
                  <div className="md:text-lg font-semibold text-[#111928]">
                    PKR {room.price.toLocaleString()} / person
                  </div>
                  <div className="text-xs text-[#637381]">TAX INCLUDED</div>
                </div>
                {selectedRooms[room.key] > 0 ? (
                  <div className="w-auto">
                    <div className="flex border border-[#D1A554] rounded-md overflow-hidden">
                      <button
                        onClick={() => handleDecrement(room.key)}
                        className="flex-1 md:px-4 md:py-3 px-2 py-2 hover:bg-gray-50 flex items-center justify-center border-r border-[#D1A554]"
                      >
                        <MinusIcon className="w-3.5 h-3.5 text-[#111928]" />
                      </button>
                      <div className="flex-1 md:px-6 md:py-3 px-2 py-2 flex items-center justify-center text-base font-medium text-[#111928]">
                        {selectedRooms[room.key]}
                      </div>
                      <button
                        onClick={() => handleIncrement(room.key)}
                        disabled={totalSelectedRooms >= travellers}
                        className={`flex-1 md:px-4 md:py-3 px-2 py-2 hover:bg-gray-50 flex items-center justify-center border-l border-[#D1A554] ${
                          totalSelectedRooms >= travellers
                            ? "bg-gray-300 hover:bg-gray-300 text-gray-500 cursor-not-allowed"
                            : "hover:bg-gray-50"
                        }  `}
                      >
                        <PlusIcon className="w-3.5 h-3.5 text-[#111928]" />
                      </button>
                    </div>
                    <div className="text-left mt-1 text-xs text-[#123C4B]">
                      {selectedRooms[room.key]} Room @ PKR{" "}
                      {(
                        room.totalPrice * selectedRooms[room.key]
                      ).toLocaleString()}
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => handleIncrement(room.key)}
                    disabled={totalSelectedRooms >= travellers}
                    className={`w-auto px-4 lg:px-2 xl:px-4 py-3 ${
                      totalSelectedRooms >= travellers
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "bg-[#D1A554] hover:bg-[#C4A052] text-white"
                    } rounded-md flex items-center justify-center gap-2 text-sm lg:text-xs xl:text-sm font-medium`}
                  >
                    <PlusIcon className="w-4 h-4" />
                    Add room
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoomOptions;
