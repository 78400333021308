import React from "react";
import { Footer, Header } from "../../";
const Contactus = () => {
  return (
    <div>
      <Header />
      <div className="bg-gray-50 text-secondaryBlue">
        <div className="w-all-sections-width mx-auto lg:flex gap-3 py-10">
          <div className="relative w-full lg:w-1/2 mt-10">
            <div className="relative lg:flex justify-between">
              <div className="absolute hidden lg:flex top-0 left-0 h-80 w-52 bg-[radial-gradient(circle,_#d1a554_20%,_transparent_10%)] [background-size:78px_78px] [background-position:0_0,_0_0] pointer-events-none opacity-5"></div>
              <div className="relative z-10 md:p-10">
                <h2 className="text-[20px] font-semibold mb-4">Contact us</h2>
                <p className="text-[18px] md:text-[12px] lg:text-[14px] font-normal mb-4 w-full">
                  Need to get in touch with us? Either fill out the form with
                  your inquiry or find the{" "}
                  <a href="#" className="text-brandGold ">
                    department email
                  </a>{" "}
                  you'd like to contact below.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex justify-center lg:justify-end mt-10 lg:mt-0">
            <div className="bg-white md:p-10 p-5 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-[18px] font-semibold mb-6 ">Contact us</h2>
              <p className="mb-6 text-gray-600">
                Need to get in touch with us? Either fill out the form with your
                inquiry or find the{" "}
                <a href="#" className=" text-brandGold ">
                  department email
                </a>{" "}
                you'd like to contact below.
              </p>
              <form className="space-y-4">
                <div className="md:flex md:space-x-4 space-y-3 md:space-y-0">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="firstName"
                      className="flex text-sm font-medium "
                    >
                      First name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
                    />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium "
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="flex text-sm font-medium">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium "
                  >
                    What can we help you with?
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm  sm:text-sm resize-none"
                  ></textarea>
                </div>
                <button className="w-full py-2 px-4 bg-brandGold  text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;
