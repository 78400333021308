import React from "react";
import visa from "../../../assets/homepage/includePackageImg/visa.png";
import ticket from "../../../assets/homepage/includePackageImg/airTickets.png";
import insurance from "../../../assets/homepage/includePackageImg/insurance.png";
import makkahZiyarah from "../../../assets/homepage/includePackageImg/makkahZiyarah.png";
import madinahZiyarah from "../../../assets/homepage/includePackageImg/madinahZiyarah.png";
import tourGuide from "../../../assets/homepage/includePackageImg/tourGuide.png";
import support from "../../../assets/homepage/includePackageImg/support.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const ServicesSectionArabic = () => {
  const data = [
    { imagee: visa, text: "تأشيرات السعودية" },
    { imagee: ticket, text: "تذاكر الطيران" },
    { imagee: insurance, text: "تأمين" },
    { imagee: makkahZiyarah, text: "زيارة مكة" },
    { imagee: madinahZiyarah, text: "المدينة المنورة الزيارة" },
    { imagee: tourGuide, text: "المرشدين السياحيين" },
    { imagee: support, text: "دعم 24/7" },
  ];

  return (
    <div className="w-all-sections-width mx-auto my-s10 md:my-m12 lg:my-l14 sm:py-0 py-4 text-secondaryBlue">
      <p className="h3 text-center sm:text-right">عروضنا تشمل</p>

      {/* only XL screen */}
      <div className="xl:flex justify-between pt-8 hidden">
        {data.map((item) => (
          <div key={item.text} className="flex flex-col items-center space-y-4">
            <img
              src={item.imagee}
              alt={item.text}
              className="xl:w-[35px] xl:h-[35px]"
              style={{
                position: "relative", // Needed for `top` and `left`
                top: "1.78px",
                left: "4.58px",
                gap: "0px",
                opacity: "1", // Default opacity, adjust if needed
              }}
            />
            <p className="text-[18px] font-normal text-primaryGray">
              {item.text}
            </p>
          </div>
        ))}
      </div>

      {/* Mobile view to Large screen except XL */}
      <div className="flex justify-between mt-s10 xl:hidden">
        <Swiper
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 2, // Show 2 slides on small screens
            },
            720: {
              slidesPerView: 4, // Show 4 slides on screens above 720px
            },
            1024: {
              slidesPerView: 5, // Show 5 slides on screens above 1024px
            },
          }}
          className="mySwiper"
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center space-y-4">
                <img
                  src={item.imagee}
                  alt={item.text}
                  className="w-[30px] h-[30px] sm:w-[28px] sm:h-[28px] md:w-[30px] md:h-[30px] lg:w-[32px] lg:h-[32px]"
                  style={{
                    position: "relative", // Needed for `top` and `left`
                    top: "1.78px",
                    left: "4.58px",
                    gap: "0px",
                    opacity: "1", // Default opacity
                  }}
                />
                <p className="text-[16px] font-semibold text-primaryGray">
                  {item.text}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ServicesSectionArabic;
