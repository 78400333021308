import React, { useState } from "react";
import cardImg from "../../../assets/Payment/card.png";
import jazzCard from "../../../assets/Payment/jazz.svg";
import voucherImg from "../../../assets/Payment/voucher.svg";
import bankImg from "../../../assets/Payment/bank1.svg";
import { BankingInfo, JazzInfo, PaymentInfo, VoucherInfo } from "../../../";
const PaymentOption = ({
  transferID,
  setTransferID,
  validateTransferID,
  previewFile,
  handleFileChange,
  errors,
}) => {
  const [clickRadio, setClickRadio] = useState("");
  const handleRadio = (type) => {
    setClickRadio(type);
  };
  return (
    <div className="text-secondaryBlue">
      <p className="text-[24px] font-600 mb-2">Payment Method</p>
      <div className="p-6 gap-2 grid lg:grid-cols-3 xl:grid-cols-4 border-[1px] border-[#DFE4EA] bg-white rounded-md">
        <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "card" ? "border-[#3fb2aa]" : "border-[#DFE4EA]"
          }`}
          onClick={() => handleRadio("card")}
        >
          <img src={cardImg} alt="" />
          <p>Card</p>
          <input
            type="radio"
            name="card"
            value="card"
            checked={clickRadio === "card"}
            onChange={() => handleRadio("card")}
          />
        </div>
        <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "jazz" ? "border-[#3fb2aa]" : "border-[#dfe4ea]"
          }`}
          onClick={() => handleRadio("jazz")}
        >
          <img src={jazzCard} alt="" />
          <p>JazzCash</p>
          <input
            type="radio"
            name="jazz"
            value="jazz"
            checked={clickRadio === "jazz"}
            onChange={() => handleRadio("jazz")}
          />
        </div>{" "}
        <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "bank" ? "border-[#3fb2aa]" : "border-[#dfe4ea]"
          }`}
          onClick={() => handleRadio("bank")}
        >
          <img src={voucherImg} alt="" />
          <p>Bank</p>
          <input
            type="radio"
            name="bank"
            value="bank"
            checked={clickRadio === "bank"}
            onChange={() => handleRadio("bank")}
          />
        </div>{" "}
        <div
          className={`flex justify-between items-center border-2 p-2 rounded-md cursor-pointer ${
            clickRadio === "voucher" ? "border-[#3fb2aa]" : "border-[#dfe4ea]"
          }`}
          onClick={() => handleRadio("voucher")}
        >
          <img src={bankImg} alt="" />
          <p>Voucher</p>
          <input
            type="radio"
            name="voucher"
            value="voucher"
            checked={clickRadio === "voucher"}
            onChange={() => handleRadio("voucher")}
          />
        </div>
      </div>
      {clickRadio === "card" && (
        <PaymentInfo
          transferID={transferID}
          setTransferID={setTransferID}
          validateTransferID={validateTransferID}
          errors={errors}
        />
      )}
      {clickRadio === "bank" && (
        <BankingInfo
          transferID={transferID}
          setTransferID={setTransferID}
          validateTransferID={validateTransferID}
          errors={errors}
        />
      )}
      {clickRadio === "jazz" && (
        <JazzInfo
          transferID={transferID}
          setTransferID={setTransferID}
          validateTransferID={validateTransferID}
          errors={errors}
        />
      )}
      {clickRadio === "voucher" && (
        <VoucherInfo
          previewFile={previewFile}
          handleFileChange={handleFileChange}
          errors={errors}
        />
      )}
    </div>
  );
};

export default PaymentOption;
