import React from "react";
import PackageCard from "./PackageCard";
import { MessageDisplay } from "../../../";

const PackageListing = ({
  packages,
  loading,
  onPageChange,
  pagination,
  error,
  filters,
}) => {
  return (
    <section className="w-full mx-auto">
      {/* Packages Grid */}
      <div className="space-y-8">
        {loading ? (
          <MessageDisplay message="Loading packages..." type="loading" />
        ) : error ? (
          <MessageDisplay
            message="There was an issue loading the packages. Please try again."
            type="error"
          />
        ) : packages.length > 0 ? (
          packages.map((pkg) => (
            <PackageCard key={pkg.huz_token} data={pkg} filters={filters} />
          ))
        ) : (
          <MessageDisplay
            message="No packages found matching your criteria."
            type="no-data"
          />
        )}
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-8">
        <button
          onClick={() => onPageChange("prev")}
          disabled={pagination.currentPage === 1}
          className={`px-4 py-2 rounded-md border 
            ${
              pagination.currentPage > 1
                ? "border-brandGold text-brandGold hover:bg-brandGold hover:text-white transition duration-200"
                : "border-lightGray text-lightGray cursor-not-allowed"
            }`}
        >
          Previous
        </button>
        <span className="text-secondaryGray text-sm">{`Page ${pagination.currentPage}`}</span>
        <button
          onClick={() => onPageChange("next")}
          className={`px-4 py-2 rounded-md border 
             ${
               pagination.currentPage >=
               Math.ceil(packages.length / pagination.pageSize)
                 ? "border-brandGold text-brandGold hover:bg-brandGold hover:text-white transition duration-200"
                 : "border-lightGray text-lightGray cursor-not-allowed"
             }`}
        >
          Next
        </button>
      </div>
    </section>
  );
};

export default PackageListing;
