import React, { useContext, useState } from "react";
import { AiOutlineMenu, AiOutlineClose, AiOutlineUser } from "react-icons/ai";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import logoIcon from "../../../assets/logoIcon.png";
import AuthContext from "../../../context/AuthContext";
import { Login, Modal, SignUp } from "../../../index";
import { useModal } from "../../../context/ModalContext";
const navLinks = [
  { href: "/Home-Page-Arabic", label: "بيت" },
  { href: "/about", label: "عن" },
  { href: "/contact", label: "اتصال" },
];

const HeaderArabic = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [lnIsOpen, setLnIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const { pathname } = useLocation();
  const { login, signOut } = useContext(AuthContext);
  const { setIsModalOpen } = useModal();
  const navigate = useNavigate();
  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };
  const openLoginModal = () => {
    setModalType("login");
    setIsModalOpen(true);
  };

  // Open SignUp Modal
  const openSignUpModal = () => {
    setModalType("signup");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalType(null); // Close modal
    setIsModalOpen(false);
  };
  const handleLanguageClick = () => {
    setLnIsOpen(!lnIsOpen);
  };
  const handleLanguageNavigate = () => {
    navigate("/Home-Page-Arabic");
  };
  const handleLanguageNavigateByOriginal = () => {
    navigate("/");
  };
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const isHomeRoute = pathname === "/Home-Page-Arabic"; // Check if the current route is home

  const renderLinks = (isMobile = false) =>
    navLinks.map(({ href, label }) => (
      <a
        key={href}
        href={href}
        className={`text-[16px] font-medium leading-[24px] ${
          pathname === href
            ? "text-brandGold"
            : isHomeRoute
            ? "text-white hover:text-brandGold"
            : "text-white   hover:text-brandGold"
        } ${isMobile ? "py-4" : ""}`}
        onClick={isMobile ? toggleMenu : null}
      >
        {label}
      </a>
    ));

  return (
    <header className="w-all-sections-width mx-auto flex justify-between items-center py-8">
      {/* Logo */}
      <div className="flex items-center gap-4">
        <img src={logoIcon} alt="Logo Icon" className=" w-[28.95px] h-[40px]" />
        <a
          href="/"
          className={`text-[28px] font-medium leading-[40px] tracking-wider text-white `}
        >
          HajjUmrah.co
        </a>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex gap-10">{renderLinks()}</nav>

      {/* Mobile Hamburger Icon */}
      <button
        onClick={toggleMenu}
        aria-label="Toggle Menu"
        className={`lg:hidden ${isHomeRoute ? "text-white" : "text-black"}`}
      >
        {isMenuOpen ? (
          <AiOutlineClose size={24} />
        ) : (
          <AiOutlineMenu size={24} />
        )}
      </button>
      <div className="hidden lg:flex gap-6 items-center">
        {/* <p
          onClick={handleLanguageClick}
          className={`text-[16px] cursor-pointer font-medium leading-[24px] ${
            pathname === "/Home-Page-Arabic"
              ? "text-white hover:text-brandGold"
              : "text-primaryGray   hover:text-brandGold"
          }`}
        >
          لغة
        </p> */}
        <button
          onClick={handleLanguageNavigateByOriginal}
          className={`text-[16px] cursor-pointer font-medium leading-[24px] ${
            pathname === "/Home-Page-Arabic"
              ? "text-white hover:text-brandGold"
              : "text-primaryGray   hover:text-brandGold"
          }`}
        >
          English{" "}
        </button>
        {/* {lnIsOpen && (
          <div className="absolute cursor-pointer hover:text-brandGold mt-32 bg-white shadow-lg rounded-md py-2 w-48 z-50">
            <button
              onClick={handleLanguageNavigate}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              عربي
            </button>
            <button
              onClick={handleLanguageNavigateByOriginal}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              English{" "}
            </button>
          </div>
        )} */}
        {login ? (
          <div className="relative">
            <button
              className={`flex items-center gap-2 text-[16px] font-medium ${
                isHomeRoute ? "text-white" : "text-black"
              }`}
              onClick={handleDropdownClick}
            >
              <AiOutlineUser
                size={40}
                className="p-2 bg-brandGold text-white rounded-3xl"
              />
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md py-2 w-48 z-50">
                <button
                  onClick={() => {
                    signOut();
                    setIsOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  تسجيل الخروج
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <button
              onClick={openLoginModal}
              className={`text-[16px] font-medium leading-[24px] ${
                isHomeRoute
                  ? "text-white hover:text-brandGold"
                  : "text-primaryGray  hover:text-brandGold"
              }`}
            >
              تسجيل الدخول
            </button>
            <button
              onClick={openSignUpModal}
              className="px-6 py-2 border border-brandGold text-brandGold rounded-full hover:bg-brandGold hover:text-white"
            >
              اشتراك
            </button>
          </>
        )}
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ duration: 0.3 }}
          className={`absolute pb-6 top-20 left-0 w-full ${
            isHomeRoute
              ? "bg-black bg-opacity-80 text-white"
              : "bg-white text-lightGray"
          } flex flex-col items-center lg:hidden z-50`}
        >
          {renderLinks(true)}
          {login ? (
            <div className="relative">
              {/* User Profile Dropdown */}
              <button
                className={`flex items-center gap-2 text-[16px] font-medium ${
                  isHomeRoute ? "text-white" : "text-black"
                }`}
                onClick={handleDropdownClick}
              >
                <AiOutlineUser size={24} />
              </button>

              {isOpen && (
                <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md py-2 w-48 z-50">
                  <button
                    onClick={() => {
                      signOut();
                      setIsOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              {/* Login Button */}
              <button
                className={`text-[16px] font-medium leading-[24px] mt-4 ${
                  isHomeRoute ? "hover:text-brandGold" : "hover:text-brandGold"
                }`}
                onClick={openLoginModal}
              >
                Login
              </button>
              <button
                className="px-6 py-2 border border-brandGold text-brandGold rounded-full mt-4 hover:bg-brandGold hover:text-white"
                onClick={openSignUpModal}
              >
                Sign Up
              </button>
            </>
          )}
        </motion.div>
      )}
      <Modal isOpen={modalType !== null} onClose={closeModal}>
        {modalType === "login" ? (
          <Login closeModal={closeModal} openSignUpModal={openSignUpModal} />
        ) : (
          <SignUp closeModal={closeModal} openLoginModal={openLoginModal} />
        )}
      </Modal>
    </header>
  );
};

export default HeaderArabic;
