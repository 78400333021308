import React from "react";
import { Footer, Header } from "../../../../";

const UmrahGuidance = () => {
  const umrahSteps = [
    "Assuming Ihram and making niyyah",
    "Entering the Masjid al-Haram",
    "Performing Tawaf",
    "After Tawaf Start Safa Marwah",
  ];

  const womenGuidelines = [
    "Men will repeat the Talbiyah loudly, while women should recite this quietly.",
    "In the first three rounds of Tawaf, the Sunnah for men is to run, which is called Raml. However, women do not follow this practice.",
    "During Sa’i, men should run between the fluorescent green lights, while women should walk throughout.",
    "Islamically, women should travel to Umrah accompanied by a mahram.",
    "In order to break from the state of Ihram, men shave their heads, while women cut the length of a fingertip.",
  ];

  const eligibilityConditions = [
    { title: "Islam", description: "It is necessary to be a Muslim." },
    {
      title: "Maturity",
      description: "You must have reached puberty (Baligh).",
    },
    {
      title: "Sanity",
      description:
        "You should be mentally sound and in control of your mental faculties.",
    },
    {
      title: "Financially able",
      description:
        "You must have enough funds to cover the expenses of travel, accommodation, and other requirements during your Umrah. Being debt-free is recommended.",
    },
    {
      title: "Physically able",
      description:
        "You must be physically capable of travelling to Makkah and performing the rituals of Umrah, either on foot or in a wheelchair.",
    },
    {
      title: "Transportation",
      description:
        "You should have access to transportation and be able to afford the costs of travelling to Makkah for Umrah. The journey should also be safe.",
    },
    {
      title: "Visa rules",
      description:
        "Certain conditions must be met to obtain an Umrah Visa, such as holding a valid passport for at least six months.",
    },
  ];

  const forbiddenActions = [
    "Applying perfume and perfumed cosmetics for men and women.",
    "Women covering their face, wearing tight-fitted clothing, or wearing hand gloves.",
    "Men covering their head with a cap, or wearing stitched clothing.",
    "Trimming nails.",
    "Cutting hair.",
    "Getting married (permissible by some scholars but not recommended).",
    "Engaging in sexual activity.",
    "Hunting an animal.",
    "Cutting down a tree.",
    "Killing an insect (unless it poses a threat to you).",
    "Smoking or taking drugs.",
    "Engaging in a quarrel or fight.",
    "Swearing and backbiting.",
  ];

  return (
    <div className="text-secondaryBlue">
      <Header />
      <div className="w-all-sections-width mx-auto py-10">
        <h1 className="text-4xl font-bold text-center">
          Step by step guide to performing Umrah
        </h1>

        <div className="py-8 space-y-4">
          {umrahSteps.map((step, index) => (
            <h2 key={index} className="text-[20px] mb-4 font-bold">
              {step}
            </h2>
          ))}

          <section className="mb-8">
            <h2 className="text-[20px] mb-4 font-bold">
              How do you perform Umrah for the first time?
            </h2>
            <p className="text-[16px] mb-4">
              You can also find everything you need to know about how to book
              your Umrah trip 
              <a
                href="https://www.islamic-relief.org.uk/resources/knowledge-base/umrah/how-to-book-your-umrah-trip/"
                className="text-blue-600 underline pl-1"
              >
                here
              </a>
              , as well as the rules of performing Umrah
              <a
                href="https://www.islamic-relief.org.uk/resources/knowledge-base/umrah/rules-of-performing-umrah/"
                className="text-blue-600 underline pl-1"
              >
                here
              </a>
              .
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] mb-4 font-bold">
              How to perform Umrah for ladies?
            </h2>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px]">
              {womenGuidelines.map((guideline, index) => (
                <li key={index}>{guideline}</li>
              ))}
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] mb-4 font-bold">
              The conditions of performing Umrah
            </h2>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px]">
              {eligibilityConditions.map((condition, index) => (
                <li key={index}>
                  <strong>{condition.title}:</strong> {condition.description}
                </li>
              ))}
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-[20px] mb-4 font-bold">
              What should be avoided during Umrah?
            </h2>
            <ul className="list-disc list-inside ml-4 mt-2 space-y-1 text-[16px]">
              {forbiddenActions.map((action, index) => (
                <li key={index}>{action}</li>
              ))}
            </ul>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UmrahGuidance;
