// src/pages/HomePage/components/Hero.js
import React from "react";
import { SearchBarArabic } from "../../../";

const HeroArabic = () => {
  return (
    <div className="w-all-sections-width mx-auto flex flex-col items-right text-right mt-24 ">
      <h2 className="ml-3 md:ml-0 h1 font-bold mb-8">
        <p className=" text-white">العثور على الأفضل</p>
        <p className="text-brandGold">
          <span className="text-white inline-block w-3 h-3 bg-white rounded-full ml-1"></span>
          باقة العمرة
        </p>
      </h2>
      <SearchBarArabic />
    </div>
  );
};

export default HeroArabic;
